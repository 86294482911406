import React, { useState } from 'react';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_WORDPRESS_API_URL + '/wp-json/wc/v3';
const CONSUMER_KEY = 'ck_4b56e86373ec976f40d0f91ed1d0cfd878b04ac2';
const CONSUMER_SECRET = 'cs_4699a59e6027d157152a2222c6c58e131e42f022';

const AccountForm = ({ formData, handleChange, handleSubmit, loading, error }) => {
  return (
    <div className="tab-pane fade show active">Account
      {error && <div className="error-message">{error}</div>}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <form className="woocommerce-EditAccountForm edit-account" onSubmit={handleSubmit}>
          <div className="form-row form-row-first">
            <label htmlFor="account_first_name">First name&nbsp;<span className="required">*</span></label>
            <input 
              type="text" 
              className="form-control woocommerce-Input woocommerce-Input--text input-text" 
              name="account_first_name" 
              id="account_first_name" 
              autoComplete="given-name" 
              value={formData.account_first_name} 
              onChange={handleChange} 
            />
          </div>
          <div className="form-row form-row-last">
            <label htmlFor="account_last_name">Last name&nbsp;<span className="required">*</span></label>
            <input 
              type="text" 
              className="form-control woocommerce-Input woocommerce-Input--text input-text" 
              name="account_last_name" 
              id="account_last_name" 
              autoComplete="family-name" 
              value={formData.account_last_name} 
              onChange={handleChange} 
            />
          </div>
          <div className="clear"></div>

          <div className="form-row form-row-wide">
            <label htmlFor="account_display_name">Display name&nbsp;<span className="required">*</span></label>
            <input 
              type="text" 
              className="form-control woocommerce-Input woocommerce-Input--text input-text" 
              name="account_display_name" 
              id="account_display_name" 
              value={formData.account_display_name} 
              onChange={handleChange} 
            />
            <span><em>This will be how your name will be displayed in the account section and in reviews</em></span>
          </div>
          <div className="clear"></div>

          <div className="form-row form-row-wide">
            <label htmlFor="account_email">Email address&nbsp;<span className="required">*</span></label>
            <input 
              type="email" 
              className="form-control woocommerce-Input woocommerce-Input--email input-text" 
              name="account_email" 
              id="account_email" 
              autoComplete="email" 
              value={formData.account_email} 
              onChange={handleChange} 
            />
          </div>

          <fieldset>
            <legend>Password change</legend>

            <div className="form-row form-row-wide">
              <label htmlFor="password_current">Current password (leave blank to leave unchanged)</label>
              <input 
                type="password" 
                className="form-control woocommerce-Input woocommerce-Input--password input-text" 
                name="password_current" 
                id="password_current" 
                autoComplete="off" 
                value={formData.password_current} 
                onChange={handleChange} 
              />
            </div>
            <div className="form-row form-row-wide">
              <label htmlFor="password_1">New password (leave blank to leave unchanged)</label>
              <input 
                type="password" 
                className="form-control woocommerce-Input woocommerce-Input--password input-text" 
                name="password_1" 
                id="password_1" 
                autoComplete="off" 
                value={formData.password_1} 
                onChange={handleChange} 
              />
            </div>
            <div className="form-row form-row-wide">
              <label htmlFor="password_2">Confirm new password</label>
              <input 
                type="password" 
                className="form-control woocommerce-Input woocommerce-Input--password input-text" 
                name="password_2" 
                id="password_2" 
                autoComplete="off" 
                value={formData.password_2} 
                onChange={handleChange} 
              />
            </div>
          </fieldset>
          <div className="clear"></div>

          <div>
            <input type="hidden" id="save-account-details-nonce" name="save-account-details-nonce" value="4eb1d7acfe" />
            <input type="hidden" name="_wp_http_referer" value="/demo/my-account/edit-account/" />
            <button type="submit" className="shop-detail-btn woocommerce-Button button" name="save_account_details" value="Save changes">Save changes</button>
            <input type="hidden" name="action" value="save_account_details" />
          </div>
        </form>
      )}
    </div>
  );
};

export default AccountForm;
