const QuoteSection = ({defend_watermark_image, defend_description}) => {
  
  return (
    <section className="quote-sec">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="quote-inner">
              <div
                className="quote-bg-img"
                style={{ backgroundImage: `url(${defend_watermark_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content/', '')})` }}
              ></div>
              <div className="quote-text">
                <p id="quote" dangerouslySetInnerHTML={{ __html: defend_description }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuoteSection;
