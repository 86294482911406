import React, { useState } from 'react';

const QuantityBox = ({ onQuantityChange }) => {

  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (event) => {
    const newQuantity = event.target.value;
    setQuantity(newQuantity);
    onQuantityChange(newQuantity);
  };

  const incrementQuantity = () => {
    setQuantity(prevQuantity => {
      const newQuantity = parseInt(prevQuantity) + 1;
      onQuantityChange(newQuantity);
      return newQuantity;
    });
  };

  const decrementQuantity = () => {
    setQuantity(prevQuantity => {
      if (prevQuantity > 1) {
        const newQuantity = parseInt(prevQuantity) - 1;
        onQuantityChange(newQuantity);
        return newQuantity;
      }
      return prevQuantity;
    });
  };

  return (
    <div className="qty-box">
      <h6>QTY</h6>
      <div className="qty-input-box">
        <button className="decrement-btn" onClick={decrementQuantity}><i className="fa fa-minus" ></i></button>     
        <input
          type="number"
          id="qty_display"
          name="quantity"
          value={quantity}
          min="1"
          onChange={handleQuantityChange}
        />
        <button onClick={incrementQuantity} className="increment-btn"><i className="fa fa-plus"></i></button>
      </div>
    </div>
  );
};

export default QuantityBox;
