import axios from 'axios';

const BASE_URL = process.env.REACT_APP_WORDPRESS_API_URL + '/wp-json/wc/v3';
const CONSUMER_KEY = 'ck_4b56e86373ec976f40d0f91ed1d0cfd878b04ac2';
const CONSUMER_SECRET = 'cs_4699a59e6027d157152a2222c6c58e131e42f022';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  auth: {
    username: CONSUMER_KEY,
    password: CONSUMER_SECRET,
  },
});

export default axiosInstance;
