import axiosInstance from './axiosConfig';
const userId = localStorage.getItem('userId');
export const getOrders = async () => {
  const response = await axiosInstance.get('/orders');
  return response.data;
};

export const getAddresses = async () => {

  const response = await axiosInstance.get('/customers/'+userId);  
  return response.data;
  
};