const ShippingSection = ({ formData, handleChange }) => (
  <>
    <h5>Shipping address</h5>
    <div className="row g-3">
      <div className="col-12">
        <input
          type="text"
          className="form-control"
          id="shipping_address_line1"
          name="shipping_address_line1"
          placeholder="Address"
          value={formData.shipping_address_line1}
          onChange={handleChange}
        />
      </div>
      <div className="col-12">
        <input
          type="text"
          className="form-control"
          id="shipping_address_line2"
          name="shipping_address_line2"
          placeholder="Address 2"
          value={formData.shipping_address_line2}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-6">
        <select
          id="shipping_country_autocomplete"
          name="shipping_country_autocomplete"
          className="form-select"
          value={formData.shipping_country_autocomplete}
          onChange={handleChange}
        >
          <option value="">Choose Country...</option>
          <option value="US">USA</option>
          <option value="IN">Inida</option>
        </select>
      </div>
      <div className="col-md-6">
        <select
          id="shipping_state_autocomplete"
          name="shipping_state_autocomplete"
          className="form-select"
          value={formData.shipping_state_autocomplete}
          onChange={handleChange}
        >
          <option value="">Choose State...</option>
          <option value="UP">Uttar Pradesh</option>
        </select>
      </div>
      <div className="col-md-6">
        <input
          type="text"
          className="form-control"
          id="shipping_city"
          name="shipping_city"
          placeholder="City"
          value={formData.shipping_city}
          onChange={handleChange}
        />
      </div>
      
      <div className="col-md-6">
        <input
          type="text"
          className="form-control"
          id="shipping_zipcode"
          name="shipping_zipcode"
          placeholder="Zip"
          value={formData.shipping_zipcode}
          onChange={handleChange}
        />
      </div>
    </div>
  </>
);

export default ShippingSection;
