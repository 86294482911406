
const API_URL = process.env.REACT_APP_WORDPRESS_API_URL+'/wp-json/wc/v3/orders';
const TOKEN_URL = process.env.REACT_APP_WORDPRESS_API_URL+'/wp-json/jwt-auth/v1/token';

export const authenticate = async (username, password) => {
    const response = await fetch(TOKEN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });
  
    if (!response.ok) {
      throw new Error('Authentication failed');
    }
  
    const data = await response.json();
    console.log('Authentication Response:', data); // Check token format here
    return data;
  };
  

  export const placeOrder = async (orderData, token) => {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(orderData),
    });
  
    if (!response.ok) {
      throw new Error('Failed to place order');
    }
  
    return response.json();
  };
  
