import React, { useState, useEffect, Suspense } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './BannerSection.css'; // Ensure you create this CSS file
import Preloader from '../common/Preloader'; 
import { Carousel } from 'react-responsive-carousel';

const BannerSection = ({ bannerImages }) => {
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);

  useEffect(() => {
    // Set the slider as loaded after a short delay to simulate lazy loading
    const timer = setTimeout(() => {
      setIsSliderLoaded(true);
    }, 1000); // Adjust this delay as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="banner">      
        <Suspense fallback={<Preloader />}>
          <Carousel
            showArrows={true}
            autoPlay={true}
            dots= {false}
            infiniteLoop={true}
            showStatus={false}
            showThumbs={false}
            emulateTouch={true} 
            dynamicHeight={true}
          >
            {bannerImages.map((image, index) => (
              <div key={index}>
                <img
                  loading="lazy"
                  alt={`banner-desktop-${index}`}
                  className="desktop-img"
                  src={image.slider_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content', '')}
                />
              </div>
            ))}
          </Carousel>
        </Suspense>   
    </section>
  );
};

export default BannerSection;
