import { FETCH_FOOTER_SUCCESS, FETCH_FOOTER_ERROR } from '../actions/footerActions';

const initialState = {
  data: null,
  error: null,
};

const footerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FOOTER_SUCCESS:
      return { ...state, data: action.payload, error: null };
    case FETCH_FOOTER_ERROR:
      return { ...state, data: null, error: action.payload };
    default:
      return state;
  }
};

export default footerReducer;
