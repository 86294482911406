// thunks/footerThunk.js
import axios from 'axios';
import { fetchFooterSuccess, fetchFooterError } from '../redux/actions/footerActions';

export const fetchFooterData = () => async (dispatch) => {
  try {
    const response = await axios.get('/json/footer-options.json');
    dispatch(fetchFooterSuccess(response.data));
  } catch (error) {
    dispatch(fetchFooterError(error.message));
  }
};
