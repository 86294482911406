import React from 'react';
import OrderItem from './OrderItem';

const OrderItems = ({ items }) => {
  const subtotal = items.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2);
  const total = subtotal; // Adjust this if you have additional calculations for total

  return (
    <table className="table">
      <tbody>
        <tr>
          <td>Product</td>
          <td>Subtotal</td>
        </tr>
        {items.map((item, index) => (
          <OrderItem key={index} item={item} />
        ))}
        <tr>
          <td>Subtotal</td>
          <td>${subtotal}</td>
        </tr>
        <tr>
          <td>Shipping</td>
          <td>Free shipping</td>
        </tr>
        <tr>
          <td>Total</td>
          <td className="total-amount">${total}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderItems;
