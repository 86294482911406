import React from 'react';

const SocialLink = ({ href, iconClass, ariaLabel }) => (
  <li>
    <a href={href} target="_blank" rel="noopener noreferrer" className="btnIcon" aria-label={ariaLabel}>
      <i className={iconClass}></i>
    </a>
  </li>
);

export default SocialLink;