import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts } from '../redux/actions/productActions';
import { addToCart } from '../redux/actions/cartActions';
import { setFilters } from '../redux/actions/filterActions';
import ShopBanner from '../components/listing/ShopBanner';
import ProductCard from '../components/listing/ProductCard';
import ShopFilter from '../components/listing/ShopFilter';
import DonateSection from '../components/common/DonateSection';


const Shop = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productList = useSelector(state => state.products);
  const { items: products, loading, error } = productList;

  const filterState = useSelector(state => state.filters);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const productsPerPage = 12;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  useEffect(() => {
    if (products) {
      filterProducts();
    }
  }, [products, filterState]);

  const filterProducts = useCallback(() => {
    if (!products) return;
    const filtered = products.filter(product => {
      // Filter by categories
      const inCategory = filterState.categories.length === 0 || 
                         filterState.categories.some(catId => product.categories.includes(catId));
      // Filter by price range
      const inPriceRange = parseFloat(product.price) >= filterState.minPrice && parseFloat(product.price) <= filterState.maxPrice;
      // Filter by color
      const inColor = filterState.colors.length === 0 || 
                      (product.product_attributes?.color?.length && filterState.colors.some(color => product.product_attributes.color.includes(color)));
      // Filter by size
      const inSize = filterState.sizes.length === 0 || 
                     (product.product_attributes?.size?.length && filterState.sizes.some(size => product.product_attributes.size.includes(size)));

      return inCategory && inPriceRange && inColor && inSize;
    });
    setVisibleProducts(filtered.slice(0, productsPerPage));
  }, [products, filterState, productsPerPage]);

  const loadMoreProducts = () => {
    setTimeout(() => {
      const currentLength = visibleProducts.length;
      const moreProducts = products.slice(currentLength, currentLength + productsPerPage);
      setVisibleProducts([...visibleProducts, ...moreProducts]);
    }, 100);
  };

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) return;
    loadMoreProducts();
  }, [visibleProducts, loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const updateFilters = useCallback((categories, minPrice, maxPrice, colors, sizes) => {
    dispatch(setFilters(categories, minPrice, maxPrice, colors, sizes));
  }, [dispatch]);

  const handleAddToCart = (product, quantity = 1) => {
    if (product) {
      const item = product.variation ? { ...product.variation, qty: Number(quantity) } : { ...product, qty: Number(quantity) };    
      dispatch(addToCart(item, Number(quantity)));
    }
  };

  return (
    <>
      <ShopBanner title="Shop" />
      <section className="category-detail-sec">
        <div className="container-fluid">
          <div className="category-detail-container">
            <div className="row g-4">
              <div className="col-lg-3">
                <ShopFilter updateFilters={updateFilters} />
              </div>
              <div className="col-lg-9">
                <div className="row g-4">
                  {visibleProducts.map((product, index) => (
                    <ProductCard                     
                      product={product}
                      handleAddToCart={handleAddToCart}
                    />
                  ))}
                  {loading && <div>Loading more...</div>}
                  {error && <div>{error}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DonateSection/>
    </>
  );
};

export default Shop;
