import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BillingSection from '../components/checkout/BillingSection';
import ShippingSection from '../components/checkout/ShippingSection';
import OrderItems from '../components/checkout/OrderItems';
import OrdersSection from '../components/checkout/OrdersSection';
import PaymentForm from '../components/checkout/PaymentForm';
import '../components/checkout/Checkout.css';
import { placeOrder, authenticate } from '../utils/api/placeOrder';

const Checkout = () => {
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();

  useEffect(() => {
    if (cart.items.length === 0) {
      navigate('/shop');
    }
  }, [cart.items, navigate]);

  const [formData, setFormData] = useState({
    billing_first_name: '',
    billing_last_name: '',
    billing_company_name: '',
    billing_street_address: '',
    billing_apartment: '',
    billing_city: '',
    billing_country_autocomplete: '',
    billing_state_autocomplete: '',
    billing_zipcode: '',
    billing_mobile_no: '',
    billing_email: '',
    shipping_first_name: '',
    shipping_last_name: '',
    shipping_company_name: '',
    shipping_address_line1: '',
    shipping_address_line2: '',
    shipping_city: '',
    shipping_country_autocomplete: '',
    shipping_state_autocomplete: '',
    shipping_zipcode: '',
    shipping_mobile_no: '',
    order_comments: '',
    payment_method: 'credit_card',
    ship_to_different_address: false,
  });

  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [authToken, setAuthToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      ship_to_different_address: !prevData.ship_to_different_address,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const authResponse = await authenticate('custom', 'Info@#123password#@321');
      if (authResponse.token) {
        setAuthToken(authResponse.token);

        const orderData = {
          payment_method: formData.payment_method,
          payment_method_title: formData.payment_method === 'cod' ? 'Cash on Delivery' : 'Credit Card',
          set_paid: formData.payment_method !== 'cod',
          billing: {
            first_name: formData.billing_first_name,
            last_name: formData.billing_last_name,
            company: formData.billing_company_name,
            address_1: formData.billing_street_address,
            address_2: formData.billing_apartment,
            city: formData.billing_city,
            postcode: formData.billing_zipcode,
            country: formData.billing_country_autocomplete,
            state: formData.billing_state_autocomplete,
            phone: formData.billing_mobile_no,
            email: formData.billing_email,
          },
          shipping: formData.ship_to_different_address
            ? {
                first_name: formData.shipping_first_name,
                last_name: formData.shipping_last_name,
                company: formData.shipping_company_name,
                address_1: formData.shipping_address_line1,
                address_2: formData.shipping_address_line2,
                city: formData.shipping_city,
                postcode: formData.shipping_zipcode,
                country: formData.shipping_country_autocomplete,
                state: formData.shipping_state_autocomplete,
                phone: formData.shipping_mobile_no,
              }
            : undefined,
          line_items: cart.items.map((item) => ({
            product_id: item.id,
            quantity: item.qty,
          })),
          customer_note: formData.order_comments,
        };
        console.log(orderData)
       
        const response = await placeOrder(orderData, authToken);
        setSuccessMessage('Order placed successfully!');
      } else {
        throw new Error('Token not received');
      }
    } catch (err) {
      console.error('Failed to place order:', err);
      setError('Failed to place order. Please try again later.');
    }
  };

  return (
    <>
      <section className="checkout-sec">
        <div className="container-fluid">
          <div className="checkout-container">
            <div className="row">
              <div className="col-lg-7">
                <div className="checkout-form">
                  <a href="javascript:void(0)" className="shop-back-btn" onClick={() => navigate(-1)} style={{ color: '#000' }}>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                        <path d="M7.57031 0C7.57031 0 7.6041 6.43512 0.9994 7.70167" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10" />
                        <path d="M20 7.69922H0" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10" />
                        <path d="M1.00072 7.7002C1.00072 7.7002 7.22401 8.84734 7.1178 15.2739" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10" />
                      </svg>
                    </span> Back
                  </a>
                  <h1>Checkout</h1>
                  <h5>Contact information</h5>
                  <p>We'll use this email to send you details and updates about your order.</p>
                  <form className="row g-3" id="checkout-form" noValidate onSubmit={handleSubmit}>
                    <BillingSection formData={formData} handleChange={handleChange} />
                    {formData.ship_to_different_address && <ShippingSection formData={formData} handleChange={handleChange} />}
                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="ship_to_different_address"
                          name="ship_to_different_address"
                          checked={formData.ship_to_different_address}
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label" htmlFor="ship_to_different_address">Ship to a different address?</label>
                      </div>
                    </div>
                    <OrdersSection formData={formData} handleChange={handleChange} cart={cart} />
                    <PaymentForm totalAmount={calculateTotal(cart.items)} />
                  </form>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="cart-sec-right">
                  <h2>Order summary</h2>
                  <OrderItems items={cart.items} />
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Coupon Code:" aria-label="Coupon Code" aria-describedby="button-addon2" />
                    <button className="btn btn-outline-secondary" type="button" id="button-addon2">Apply</button>
                  </div>
                  <button type="submit" form="checkout-form" className="shop-detail-btn">CHECKOUT</button>
                </div>
              </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
          </div>
        </div>
      </section>
    </>
  );
};

const calculateTotal = (items) => {
  return items.reduce((acc, item) => acc + item.qty * item.price, 0) * 100; // Amount in cents
};

export default Checkout;
