import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CartItems from '../components/cart/CartItems';
import CartTotals from '../components/cart/CartTotals';
import  '../components/cart/Cart.css';
import DonateSection from '../components/common/DonateSection';

const Cart = () => {

  const cart = useSelector(state => state.cart);

 
  const navigate = useNavigate();

  useEffect(() => {
    if (cart.items.length === 0) {
      navigate('/shop');
    }
  }, [cart.items, navigate]); 

  const calculateSubtotal = () => {
    let subtotal = 0;
    cart.items.forEach(item => {
      subtotal += item.price * item.qty;
    });
    return subtotal;
  };

  const subtotal = calculateSubtotal();

  return (
    <>
      <section className="shop-cart-sec">
        <div className="container-fluid">
          <div className="shop-cart-container">
            <div className="row">
              <div className="col-lg-8">
                <CartItems items={cart.items} />
              </div>
              <div className="col-lg-4">
                <CartTotals subtotal={subtotal} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <DonateSection />
    </>
  );
};

export default Cart;
