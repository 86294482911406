import React from 'react';
import './Marquee.css'; // Ensure you have a CSS file for styling

const Marquee = ({ marqueeLogoImage, marqueeMessage }) => {

  const content = (
    <span className="marquee-item">
      <img
        loading="lazy"
        src={marqueeLogoImage}
        alt="Logo"
        className="img-fluid"
      />
      <span dangerouslySetInnerHTML={{ __html: marqueeMessage }} />
    </span>
  );

  return (
    <div className="marquee">
      <div className="track">
        <div className="content" id="marquee">
          {content} {content} {content} {content} {content} {content}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
