import axios from 'axios';
import { fetchHeaderSuccess, fetchHeaderError } from '../redux/actions/headerActions';

export const fetchHeaderData = () => async (dispatch) => {
  try {
    const response = await axios.get('/json/header-options.json');
    dispatch(fetchHeaderSuccess(response.data));
  } catch (error) {
    dispatch(fetchHeaderError(error.message));
  }
};
