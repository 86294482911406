import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InnerBanner from '../components/common/InnerBanner';
import Preloader from '../components/common/Preloader';
import ContactForm from '../components/contact/ContactForm'; 
import ContactQuote from '../components/contact/ContactQuote';


const Contact = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContactPageData = async () => {
      try {
        const response = await axios.get(`/json/contact.json`);       
        setContactData(response.data);
      } catch (error) {
        console.error('Error fetching contact page data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContactPageData();
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <>    
      <InnerBanner data={contactData} />
      <ContactQuote data={contactData}/>      
      <ContactForm data={contactData}/> 
    </>
  );
};

export default Contact;