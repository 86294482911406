// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import Spinner from '../common/Spinner';
import '../Auth.css'; // Import the CSS file
import {jwtDecode} from 'jwt-decode';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_WORDPRESS_API_URL + '/wp-json/jwt-auth/v1/token', {
        username,
        password,
      });
      
      const { token } = response.data;
      const decodedToken = jwtDecode(token);
   
      console.log(decodedToken)
      localStorage.setItem('token', token);
      localStorage.setItem('userId',decodedToken.data.user.id);

      onLogin(true);
    } catch (error) {
      setError('Invalid username or password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h2>Login</h2>
          {error && <p className="auth-error">{error}</p>}
          <div className="auth-input">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="auth-input">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="auth-button" onClick={handleLogin}>
            Login
          </button>
        </>
      )}
    </div>
  );
};

export default Login;
