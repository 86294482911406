import React, { useState, useEffect } from 'react';
import { getOrders, getAddresses } from '../../utils/api/woocommerce';
import Spinner from '../common/Spinner';
import AccountForm from '../my-account/AccountForm';
import axiosInstance from '../../utils/api/axiosConfig';

const TabContent = ({ activeTab, onLogout }) => {
  const [orders, setOrders] = useState([]);
  const [addresses, setAddresses] = useState({ billing: {}, shipping: {} });
  const [formData, setFormData] = useState({
    account_first_name: '',
    account_last_name: '',
    account_display_name: '',
    account_email: '',
    password_current: '',
    password_1: '',
    password_2: '',
  });
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedAddress, setEditedAddress] = useState({ billing: {}, shipping: {} });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (activeTab === 'order') {
          const ordersData = await getOrders();
          setOrders(ordersData);
        } else if (activeTab === 'address') {
          
          const addressesData = await getAddresses();      
          setAddresses(addressesData);
          setEditedAddress(addressesData); // Set initial state for editedAddress
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTab]);

  const handleViewOrder = async (orderId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/orders/${orderId}`);
      setSelectedOrder(response.data);
    } catch (error) {
      console.error('Error fetching order details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditAddress = (type) => {
    setEditMode(true);
  };

  const handleAddressChange = (e, type) => {
    setEditedAddress({
      ...editedAddress,
      [type]: {
        ...editedAddress[type],
        [e.target.name]: e.target.value,
      },
    });
  };

  const saveAddress = async (type) => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(`/customers/${addresses.id}`, {
        [type]: editedAddress[type],
      });
      setAddresses(response.data);
      setEditMode(false);
    } catch (error) {
      console.error('Error saving address:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const saveAccountDetails = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(`/customers/${formData.account_email}`, formData);
      console.log('Account details saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving account details:', error);
      setError('An error occurred while saving account details.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveAccountDetails();
  };

  const renderOrderList = () => (
    <div className="tab-pane fade show active" id="order">
      {loading ? (
        <Spinner />
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Order</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col">Total</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>
                  <a href="javascript:void(0)" onClick={() => handleViewOrder(order.id)}>
                    #{order.id}
                  </a>
                </td>
                <td>{new Date(order.date_created).toLocaleDateString()}</td>
                <td>{order.status}</td>
                <td>{order.total}</td>
                <td>
                  <a href="javascript:void(0)" onClick={() => handleViewOrder(order.id)} className="view-btn">
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );

  const renderOrderDetails = () => (
    <div className="tab-pane fade show active" id="order-details">
      <div className="woocommerce-notices-wrapper"></div>
      <p>
        Order #<mark className="order-number">{selectedOrder.id}</mark> was placed on{' '}
        <mark className="order-date">{new Date(selectedOrder.date_created).toLocaleDateString()}</mark> and is
        currently <mark className="order-status">{selectedOrder.status}</mark>.
      </p>
      <section className="order-details" id='order'>
        <h2 className="woocommerce-order-details__title">Order details</h2>
        <table className="table">
          <tbody>
            <tr>
              <td>Product</td>
              <td className="text-end">Total</td>
            </tr>
            {selectedOrder.line_items.map((item) => (
              <tr key={item.id} className="woocommerce-table__line-item order_item">
                <td className="woocommerce-table__product-name product-name">
                  <h5>{item.name}</h5>
                  <span>×&nbsp;{item.quantity}</span>
                </td>
                <td className="text-end">
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      <span className="woocommerce-Price-currencySymbol">$</span>
                      {item.total}
                    </bdi>
                  </span>
                </td>
              </tr>
            ))}
            <tr>
              <td>Subtotal:</td>
              <td className="text-end">
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  {selectedOrder.total}
                </span>
              </td>
            </tr>
            <tr>
              <td>Shipping:</td>
              <td className="text-end">{selectedOrder.shipping_total}</td>
            </tr>
            <tr>
              <td>Payment method:</td>
              <td className="text-end">{selectedOrder.payment_method_title}</td>
            </tr>
            <tr>
              <td>Total:</td>
              <td className="text-end">
                <span className="woocommerce-Price-amount amount">
                  <span className="woocommerce-Price-currencySymbol">$</span>
                  {selectedOrder.total}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="shipping-address">
        <div className="row">
          <div className="col-lg-6">
            <div className="billing-box">
              <h2 className="woocommerce-column__title">Billing address</h2>
              <address>
                {selectedOrder.billing.first_name} {selectedOrder.billing.last_name}
                <br />
                {selectedOrder.billing.address_1}
                <br />
                {selectedOrder.billing.city} {selectedOrder.billing.postcode} {selectedOrder.billing.state},{' '}
                {selectedOrder.billing.country}
                <p className="woocommerce-customer-details--phone">{selectedOrder.billing.phone}</p>
                <p className="woocommerce-customer-details--email">{selectedOrder.billing.email}</p>
              </address>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="billing-box">
              <h2 className="woocommerce-column__title">Shipping address</h2>
              <address>
                {selectedOrder.shipping.first_name} {selectedOrder.shipping.last_name}
                <br />
                {selectedOrder.shipping.address_1}
                <br />
                {selectedOrder.shipping.city} {selectedOrder.shipping.postcode} {selectedOrder.shipping.state},{' '}
                {selectedOrder.shipping.country}
              </address>
            </div>
          </div>
        </div>
      </section>
      <button onClick={() => setSelectedOrder(null)} className='btn btnDefault'>Back to Orders</button>
    </div>
  );

  const renderDashboard = () => (
    <div className="tab-pane fade show active">
      <h6>
        Hello User (not User?{' '}
        <a href="javascript:void(0)" onClick={onLogout}>
          Log out
        </a>{' '}
        )
      </h6>
      <p>
        From your account dashboard you can view your recent orders, manage your shipping and billing addresses, and
        edit your password and account details.
      </p>
    </div>
  );

  const renderSubscriptions = () => (
    <div className="tab-pane fade show active">
      <div className="subscription">
        <p>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M6.74951 7.50006L9.00761 9.19364C9.42609 9.50749 10.0157 9.44581 10.3602 9.05214L14.9995 3.75006"
                stroke="#228240"
                strokeWidth="1.3"
                strokeLinecap="round"
              />
              <path
                d="M15.75 9C15.75 10.4104 15.3082 11.7853 14.4867 12.9317C13.6652 14.0781 12.5052 14.9384 11.1697 15.3918C9.8342 15.8451 8.39022 15.8688 7.04058 15.4593C5.69094 15.0499 4.50344 14.2281 3.64487 13.1091C2.78629 11.9902 2.29976 10.6305 2.25361 9.22085C2.20747 7.81124 2.60402 6.42258 3.38758 5.2499C4.17114 4.07722 5.30234 3.17944 6.62231 2.68264C7.94228 2.18583 9.38472 2.11497 10.747 2.48"
                stroke="#228240"
                strokeWidth="1.3"
                strokeLinecap="round"
              />
            </svg>
          </span>
          You have no active subscriptions.
        </p>
        <a href="javascript:void(0)">Browse products</a>
      </div>
    </div>
  );

  const renderDownloads = () => (
    <div className="tab-pane fade show active">
      <div className="download">
        <p>
          <span>
            <i className="fa fa-window-maximize"></i>
          </span>
          No downloads available yet.
        </p>
        <a href="javascript:void(0)">Browse products</a>
      </div>
    </div>
  );

  const renderAddresses = () => (
    <div className="tab-pane fade show active">
      {loading ? (
        <p>Loading addresses2...</p>
      ) : (
        <div className="shipping-address">
          <div className="row">
            <div className="col-lg-6">
              <div className="billing-box">
                <h2>
                  Billing address{' '}
                  <a href="javascript:void(0)" onClick={() => handleEditAddress('billing')}>
                    <i className="fa-regular fa-pen-to-square"></i> Edit
                  </a>
                </h2>
                {editMode ? (
                  <>
                    <input
                      type="text"
                      name="first_name"
                      value={editedAddress.billing.first_name}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <input
                      type="text"
                      name="last_name"
                      value={editedAddress.billing.last_name}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <input
                      type="text"
                      name="address_1"
                      value={editedAddress.billing.address_1}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <input
                      type="text"
                      name="city"
                      value={editedAddress.billing.city}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <input
                      type="text"
                      name="postcode"
                      value={editedAddress.billing.postcode}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <input
                      type="text"
                      name="state"
                      value={editedAddress.billing.state}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <input
                      type="text"
                      name="country"
                      value={editedAddress.billing.country}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <input
                      type="text"
                      name="phone"
                      value={editedAddress.billing.phone}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <input
                      type="email"
                      name="email"
                      value={editedAddress.billing.email}
                      onChange={(e) => handleAddressChange(e, 'billing')}
                    />
                    <button onClick={() => saveAddress('billing')} className='btn btnDefault'>
                      Save
                    </button>
                    <button onClick={() => setEditMode(false)} className='btn btnDefault'>
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <p>
                      {addresses.billing.first_name} {addresses.billing.last_name}
                    </p>
                    <p>{addresses.billing.address_1}</p>
                    <p>
                      {addresses.billing.city} {addresses.billing.postcode} {addresses.billing.state},{' '}
                      {addresses.billing.country}
                    </p>
                    <a href="javascript:void(0)">
                      <i className="fa fa-phone-alt"></i> {addresses.billing.phone}
                    </a>{' '}
                    <br />
                    <a href="javascript:void(0)">
                      <i className="far fa-envelope"></i> {addresses.billing.email}
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="billing-box">
                <h2>
                  Shipping address{' '}
                  <a href="javascript:void(0)" onClick={() => handleEditAddress('shipping')}>
                    <i className="fa-regular fa-pen-to-square"></i> Edit
                  </a>
                </h2>
                {editMode ? (
                  <>
                    <input
                      type="text"
                      name="first_name"
                      value={editedAddress.shipping.first_name}
                      onChange={(e) => handleAddressChange(e, 'shipping')}
                    />
                    <input
                      type="text"
                      name="last_name"
                      value={editedAddress.shipping.last_name}
                      onChange={(e) => handleAddressChange(e, 'shipping')}
                    />
                    <input
                      type="text"
                      name="address_1"
                      value={editedAddress.shipping.address_1}
                      onChange={(e) => handleAddressChange(e, 'shipping')}
                    />
                    <input
                      type="text"
                      name="city"
                      value={editedAddress.shipping.city}
                      onChange={(e) => handleAddressChange(e, 'shipping')}
                    />
                    <input
                      type="text"
                      name="postcode"
                      value={editedAddress.shipping.postcode}
                      onChange={(e) => handleAddressChange(e, 'shipping')}
                    />
                    <input
                      type="text"
                      name="state"
                      value={editedAddress.shipping.state}
                      onChange={(e) => handleAddressChange(e, 'shipping')}
                    />
                    <input
                      type="text"
                      name="country"
                      value={editedAddress.shipping.country}
                      onChange={(e) => handleAddressChange(e, 'shipping')}
                    />
                    <button onClick={() => saveAddress('shipping')} className='btn btnDefault'>
                      Save
                    </button>
                    <button onClick={() => setEditMode(false)} className='btn btnDefault'>
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <p>
                      {addresses.shipping.first_name} {addresses.shipping.last_name}
                    </p>
                    <p>{addresses.shipping.address_1}</p>
                    <p>
                      {addresses.shipping.city} {addresses.shipping.postcode} {addresses.shipping.state},{' '}
                      {addresses.shipping.country}
                    </p>
                    <a href="javascript:void(0)">
                      <i className="fa fa-phone-alt"></i> {addresses.shipping.phone}
                    </a>{' '}
                    <br />
                    <a href="javascript:void(0)">
                      <i className="far fa-envelope"></i> {addresses.shipping.email}
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const renderPayment = () => (
    <div className="tab-pane fade show active">Payment</div>
  );

  const renderLogout = () => (
    <div className="tab-pane fade show active">
      <button onClick={onLogout}>Log Out</button>
    </div>
  );

  return (
    <div className="account-tab-right">
      <div className="tab-content">
        {activeTab === 'dashboard' && renderDashboard()}
        {activeTab === 'order' && (selectedOrder ? renderOrderDetails() : renderOrderList())}
        {activeTab === 'subscription' && renderSubscriptions()}
        {activeTab === 'download' && renderDownloads()}
        {activeTab === 'address' && renderAddresses()}
        {activeTab === 'payment' && renderPayment()}
        {activeTab === 'account' && (
          <AccountForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            loading={loading}
            error={error}
          />
        )}
        {activeTab === 'logout' && renderLogout()}
      </div>
    </div>
  );
};

export default TabContent;