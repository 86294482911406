import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './ClientLogoSection.css'; // Custom CSS file for marquee effect

const ClientLogoSection = ({ logoBanner }) => {
  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/150?text=No+Image"; // Provide a fallback image
  };

  return (
    <section className="client-logo">
      <div className="client-logo-slider">
        <div className="marquee">
          <div className="marquee-content">
            {logoBanner && logoBanner.map((item, index) => (
              <div className="client-logo-box" key={index}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img loading="lazy" src={item.image.replace(process.env.REACT_APP_WORDPRESS_API_URL+'/wp-content','')} alt={item.alt} onError={handleImageError} />
                </a>
              </div>
            ))}
            {logoBanner && logoBanner.map((item, index) => (
              <div className="client-logo-box" key={`duplicate-${index}`}>
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img loading="lazy" src={item.image.replace(process.env.REACT_APP_WORDPRESS_API_URL+'/wp-content/', '')} alt={item.alt} onError={handleImageError} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientLogoSection;
