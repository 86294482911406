import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INCREMENT_QUANTITY,
  DECREMENT_QUANTITY,
} from '../actions/cartActions';

const initialState = {
  items: [],
};

const saveToLocalStorage = (items) => {
  localStorage.setItem('cartItems', JSON.stringify(items));
};

export const cartReducer = (state = initialState, action) => {

  switch (action.type) {
    case ADD_TO_CART:  
      const itemExists = state.items.find((item) => item.id === action.payload.id);    
      if (itemExists) {       
          const updatedItems = state.items.map((item) =>
          item.id === action.payload.id ? { ...item, qty: item.qty + action.payload.qty} : item
        );
        saveToLocalStorage(updatedItems);
        return {
          ...state,
          items: updatedItems,
        };
      } else {
        const updatedItems = [...state.items, { ...action.payload }];
        saveToLocalStorage(updatedItems);
        return {
          ...state,
          items: updatedItems,
        };
      }

    case REMOVE_FROM_CART:
      const updatedItemsAfterRemoval = state.items.filter((item) => item.id !== action.payload.id);
      saveToLocalStorage(updatedItemsAfterRemoval);
      return {
        ...state,
        items: updatedItemsAfterRemoval,
      };

    case INCREMENT_QUANTITY:
      
      const incrementedItems = state.items.map((item) =>
        item.id === action.payload.id ? { ...item, qty: item.qty + 1 } : item
      );
      saveToLocalStorage(incrementedItems);
      return {
        ...state,
        items: incrementedItems,
      };

    case DECREMENT_QUANTITY:
      const decrementedItems = state.items.map((item) =>
        item.id === action.payload.id && item.qty > 1 ? { ...item, qty: item.qty - 1 } : item
      );
      saveToLocalStorage(decrementedItems);
      return {
        ...state,
        items: decrementedItems,
      };

    default:
      return state;
  }
};
