import React from 'react';
import Menu from './Menu';

const Footer = ({ footerOptions }) => {

  const renderSocialLinks = (icons) => {
    if (!icons) return null;
    return (
      <ul className="list-unstyled footer-social-media social-item2">
        {icons.map((icon, index) => (
          <React.Fragment key={index}>
            {icon.fb_link && (
              <li>
                <a href={icon.fb_link} target="_blank" rel="noopener noreferrer" title="Facebook" aria-label="Facebook">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
            )}
            {icon.ld_link && (
              <li>
                <a href={icon.ld_link} target="_blank" rel="noopener noreferrer" title="Linkedin" aria-label="Linkedin">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            )}
            {icon.tw_link && (
              <li>
                <a href={icon.tw_link} target="_blank" rel="noopener noreferrer" title="Twitter" aria-label="Twitter">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            )}
            {icon.instagram_link && (
              <li>
                <a href={icon.instagram_link} target="_blank" rel="noopener noreferrer" title="Instagram" aria-label="Instagram">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            )}
            {icon.envelope_link && (
              <li>
                <a href={icon.envelope_link} target="_blank" rel="noopener noreferrer" title="Envelope" aria-label="Envelope">
                  <i className="fas fa-envelope"></i>
                </a>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    );
  };

  return (
    <footer className="pb-0 footer-area-before">
      <div className="container footer-sec">
        <div className="logo-info">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-4">
              <div className="footer-logo">
                <a href="/">
                  {footerOptions.footer_logo && (
                    <img
                      src={process.env.PUBLIC_URL+"/assets/images/logo.png"}
                      alt={footerOptions.footer_logo.alt || "Academie Culinaire De FrAnce USA & Canada Delegation"}
                      className="img-fluid lazyimage"
                      id="#animate-anything-css"
                    />
                  )}
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-8">
              <div className="footer-info">
                {footerOptions.footer_heading && footerOptions.footer_heading.data && (
                  <p dangerouslySetInnerHTML={{ __html: footerOptions.footer_heading.data.footer_heading }} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-navbar" id="mobile-view">
          <div className="row justify-content-lg-between justify-content-md-between justify-content-center">
            <div className="col-md-7 mb-2 col-8">
              <div className="footer-nav">
                {footerOptions.footer_heading && footerOptions.footer_heading.data.connect_social_icons && (
                  <>
                    <div className="nav-title">
                      <p>Connect</p>
                    </div>
                    {renderSocialLinks(footerOptions.footer_heading.data.connect_social_icons)}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4 mb-4 col-12">
              <div className="footer-form">
                <div className="form-title text-center">
                  {footerOptions.subscribe_to_our_news && footerOptions.subscribe_to_our_news.data && (
                    <p dangerouslySetInnerHTML={{ __html: footerOptions.subscribe_to_our_news.data.subscribe_to_our_news }} />
                  )}
                </div>
                <div className="registration-form">
                  <form className="row g-3">
                    <div className="col-md-12">
                      <input type="email" className="form-control" placeholder="Enter email" />
                    </div>
                    <div className="col-12 d-grid">
                      <button type="submit" className="btn btnDefault">
                        <span>REGISTER</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      {footerOptions.explore_links_text && footerOptions.explore_links_text.data && footerOptions.explore_links_text.data.explore_links_text}
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="footer-nav">
                        <div className="row">
                          <div className="col-lg-6">
                            <Menu menuName="explore-primary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      {footerOptions.learn_links_text && footerOptions.learn_links_text.data && footerOptions.learn_links_text.data.learn_links_text}
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="footer-nav">
                        <Menu menuName="learn" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-navbar" id="tab-view">
          <div className="row justify-content-between">
            <div className="col-md-7 mb-4">
              <div className="footer-nav">
                {footerOptions.footer_heading && footerOptions.footer_heading.data.connect_social_icons && (
                  <>
                    <div className="nav-title">
                      <p>{footerOptions.footer_heading.data.connect_social_icons_text}</p>
                    </div>
                    <div className="social-media">
                      {renderSocialLinks(footerOptions.footer_heading.data.connect_social_icons)}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-5 mb-4">
              <div className="footer-form">
                <div className="form-title text-center">
                  {footerOptions.subscribe_to_our_news && footerOptions.subscribe_to_our_news.data && (
                    <p dangerouslySetInnerHTML={{ __html: footerOptions.subscribe_to_our_news.data.subscribe_to_our_news }} />
                  )}
                </div>
                <div className="registration-form">
                  <form className="row g-3">
                    <div className="col-md-12">
                      <input type="email" className="form-control" placeholder="Enter email" />
                    </div>
                    <div className="col-12 d-grid">
                      <button type="submit" className="btn btnDefault">
                        <span>REGISTER</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      {footerOptions.explore_links_text && footerOptions.explore_links_text.data && footerOptions.explore_links_text.data.explore_links_text}
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="footer-nav">
                        <div className="row">
                          <div className="col-lg-6">
                            <Menu menuName="explore-primary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      {footerOptions.learn_links_text && footerOptions.learn_links_text.data && footerOptions.learn_links_text.data.learn_links_text}
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <div className="footer-nav">
                        <Menu menuName="learn" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-navbar" id="desktop-view">
          <div className="row">
            <div className="col-lg-5">
              <div className="footer-nav">
                <div className="nav-title">
                  <p>Explore</p>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <Menu menuName="explore-primary" />
                  </div>
                  <div className="col-lg-6">
                    <Menu menuName="explore-secondary" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer-nav">
                <div className="nav-title">
                  <p>Learn</p>
                </div>
                <ul className="list-unstyled">
                  <Menu menuName="learn" />
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-8">
              <div className="footer-nav">
                {footerOptions.footer_heading && footerOptions.footer_heading.data.connect_social_icons && (
                  <>
                    <div className="nav-title">
                      <p>Connect</p>
                    </div>
                    <div className="social-media">
                      {renderSocialLinks(footerOptions.footer_heading.data.connect_social_icons)}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="footer-form">
                <div className="form-title text-center">
                  {footerOptions.subscribe_to_our_news && footerOptions.subscribe_to_our_news.data && (
                    <p dangerouslySetInnerHTML={{ __html: footerOptions.subscribe_to_our_news.data.subscribe_to_our_news }} />
                  )}
                </div>
                <div className="registration-form">
                  <form className="row g-3">
                    <div className="col-md-12">
                      <input type="email" className="form-control" placeholder="Enter email" />
                    </div>
                    <div className="col-12 d-grid">
                      <button type="submit" className="btn btnDefault">
                        <span>REGISTER</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              {footerOptions.copyright && footerOptions.copyright.data && (
                <p dangerouslySetInnerHTML={{ __html: footerOptions.copyright.data.copyright }} />
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
