import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const BoardMembersSection = ({ members }) => {
  const boardMembersData = members;
  const apiUrl = process.env.REACT_APP_WORDPRESS_API_URL;

  return (
    <section className="board-members">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="member-top-content custom-border-btm" id="mobile-view">
              <div className="title text-p">
                <h2 dangerouslySetInnerHTML={{ __html: boardMembersData.board_members_title }} />
              </div>
            </div>
          </div>
          <div className="board-members-slider">
            <Carousel
              showArrows={true}
              autoPlay={true}
              infiniteLoop={true}
              showStatus={false}
              showThumbs={false}
              emulateTouch={true}
              interval={3000}
            >
              {boardMembersData.board_members.map((member, index) => (
                <div className="item" key={index}>
                  <div className="row">
                    <div className="col-lg-5 col-md-6 pe-0">
                      <div className="member-img">
                        <img
                          loading="lazy"
                          src={member.member_image.replace(process.env.REACT_APP_WORDPRESS_API_URL+'/wp-content/','')}
                          alt="Member"
                          className="img-fluid lazyimage"
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6 member-content-bg ps-0 pe-0">
                      <div className="member-top-content custom-border-btm" id="desktop-view">
                        <div className="title text-p">
                          <h2 dangerouslySetInnerHTML={{ __html: boardMembersData.board_members_title }} />
                        </div>
                      </div>
                      <div className="member-content-main">
                        <div className="member-content">
                          <div className="sub-title">
                            <p dangerouslySetInnerHTML={{ __html: member.member_name }} />
                          </div>
                          <div className="title">
                            <h3 dangerouslySetInnerHTML={{ __html: member.member_position }} />
                          </div>
                          <div className="board-member-btn">
                            <a href={member.bio_link}>
                              <button className="btnDefault" aria-label="Right Arrow">
                                <span dangerouslySetInnerHTML={{ __html: member.bio_text }} />
                                <span className="ps-2">
                                  <img
                                    loading="lazy"
                                    alt="Academie Culinaire De France USA"
                                    src={`/assets/img/white-right-arrow.svg`}
                                    className="right-arrow change-img1"
                                  />
                                  <img
                                    loading="lazy"
                                    alt="Academie Culinaire De France USA"
                                    src={`/assets/img/right-arrow.svg`}
                                    className="right-arrow change-img2"
                                  />
                                </span>
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-end">
                          <div className="custom-btn">
                            <a href={boardMembersData.bm_see_all_recipes_link} className="border-animate">
                              <span dangerouslySetInnerHTML={{ __html: boardMembersData.bm_see_all_recipes_text }} />
                              <img
                                loading="lazy"
                                src={`/assets/img/right-arrow.svg`}
                                className="right-arrow"
                                alt="right-arrow"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoardMembersSection;
