import React from 'react';

const MyAccountBanner = ({ title }) => {
  if (!title) return null;

  const bannerStyle = {
    backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0.75%, rgba(0, 0, 0, 0.00) 111.99%), url(${process.env.PUBLIC_URL}/assets/images/shop-banner.png)`,
  };

  return (
    <section className={`page-banner page-border`} style={bannerStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 px-0">
            <div className={`page-banner-title`}>
              <h2>{title}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyAccountBanner;
