import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BannerSection from '../components/home/BannerSection';
import Marquee from '../components/home/Marquee';
import AboutUsSection from '../components/home/AboutUsSection';
import ChefsRecipesSection from '../components/home/ChefsRecipesSection';
import QuoteSection from '../components/home/QuoteSection';
import BoardMembersSection from '../components/home/BoardMembersSection';
import PartnerSection from '../components/home/PartnerSection';
import ClientLogoSection from '../components/home/ClientLogoSection';
import Preloader from '../components/common/Preloader';



const Home = () => {

  const [homeData, setHomeData] = useState(true);  
 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
       
        const response = await axios.get('/json/home.json');      
        setHomeData(response.data);       
      
      } catch (error) {
        console.error('Error fetching home page data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <Preloader />;
  }

  if (!homeData) {
    return <></>;
  }

  return (
    <>
      <BannerSection bannerImages={homeData.acf.banner_images} />
      <Marquee marqueeLogoImage={homeData.acf.logo_image} marqueeMessage={homeData.acf.welcome_message}/>
      <AboutUsSection aboutUsData={homeData.acf}/>
      <ChefsRecipesSection recipesData={homeData.acf} />
      <QuoteSection defend_watermark_image={homeData.acf.defend_watermark_image} defend_description={homeData.acf.defend_description} />
      <BoardMembersSection members={homeData.acf} />
      <PartnerSection partnerData={homeData.acf} />
      <ClientLogoSection logoBanner={homeData.logo_banner} />     
    </>
  );
};

export default Home;
