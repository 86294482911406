import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../../redux/actions/cartActions';

const CartItems = ({ items }) => {
 
  const dispatch = useDispatch();

  const handleIncrement = (product) => {
    dispatch(incrementQuantity(product));
  };

  const handleDecrement = (product) => {
    dispatch(decrementQuantity(product));
  };

  const handleRemove = (product) => {
    dispatch(removeFromCart(product));
  };

  return (
    <div className="cart-sec-left">
      <a href="javascript:void(0)" className="shop-back-btn">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
            <path d="M7.57031 0C7.57031 0 7.6041 6.43512 0.9994 7.70167" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10" />
            <path d="M20 7.69922H0" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10" />
            <path d="M1.00072 7.7002C1.00072 7.7002 7.22401 8.84734 7.1178 15.2739" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10" />
          </svg>
        </span> 
        Back
      </a>
      <h2>Your Cart</h2>
      {items.map(item => (
        <div className="cart-details" key={item.id}>
         <img src={`/uploads/${item.image}`} alt={item.name} className="img-fluid" />
          <div className="cart-details-txt">
          <Link to={`/product/${item.slug}`}><p>{item.name}</p></Link>
            <div className="cart-details-amount">
              <h4>${item.price} <span>${item.originalPrice}</span></h4>
              <div className="qty-input-box">
                <div className="product_data" id="qty_select">
                  <input type="hidden" value={item.id} className="prod_id" />
                  <div className="input-group text-center" id="qty_selector">
                    <a className="decrement-btn" onClick={() => handleDecrement(item)}>
                      <i className="fa fa-minus" style={{ paddingLeft: '9px' }}></i>
                    </a>
                    <input type="text" readOnly id="qty_display" className="qty-input text-center" value={item.qty} />
                    <a className="increment-btn" onClick={() => handleIncrement(item)}>
                      <i className="fa fa-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="javascript:void(0)" className="cart-remove-btn" onClick={() => handleRemove(item)}>Remove</a>
        </div>
      ))}
    </div>
  );
};

export default CartItems;
