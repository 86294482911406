
const OrdersSection = ({ formData, handleChange, cart }) => (
  <>
    <h4 className="formHeading">ORDER NOTES</h4>
    <div className="defaultFormRow">
      <label className="rowLabel">Order Comments</label>
      <div className="rowInput">
        <textarea
          id="order_comments"
          className="form-control"
          name="order_comments"
          placeholder="Notes about your order, e.g. special notes for delivery."
          value={formData.order_comments}
          onChange={handleChange}
        />
        <span></span>
      </div>
    </div>
   
  </>
);

export default OrdersSection;
