import React from 'react';
import './ProductCard.css';
import { Link } from 'react-router-dom';

const ProductCard = ({ product, handleAddToCart }) => {

 
  const minPrice = product.product_type=='variable' ? Math.min(...product.price_array) : product.price;
  const maxPrice = product.product_type=='variable' ? Math.max(...product.price_array) : null;


  return (
    <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="card h-100 category-box">
        <div className="category-img">
          <Link to={`/product/${product.slug}`}>
            <img
              src={`${process.env.REACT_APP_FRONTEND_URL}/uploads/${product.images}`}
              className="card-img-top"
              alt={product.name}
            />
          </Link>
        </div>
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">
            <Link to={`/product/${product.slug}`}>{product.name}</Link>
          </h5>
          <p className="card-text mt-auto">
            <span>$</span>{minPrice}
            {product.product_type=='variable' && (
              <span> - <span>$</span>{maxPrice}</span>
            )}
          </p>
        </div>
        <div className="card-footer">
        {product.product_type == 'simple' ?          
          <button 
            className="cart-btn" 
            onClick={() => handleAddToCart(product)}
          >
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                <path
                  d="M18 5.23635V6.1452H17.1044L16.708 8.14254C16.3395 9.99893 15.9848 11.8573 15.5976 13.7107C15.4937 14.2185 15.2169 14.6745 14.8143 15.001C14.4117 15.3274 13.9084 15.5041 13.3901 15.5009C10.464 15.5121 7.53799 15.5121 4.61195 15.5009C3.47003 15.4963 2.57644 14.7025 2.34948 13.5565C1.8691 11.1139 1.38635 8.67127 0.901219 6.22866C0.897657 6.21034 0.89155 6.19202 0.881882 6.15792H0V5.24194H4.25319C4.49949 4.53359 4.74121 3.83795 4.98394 3.14283C5.14271 2.68738 5.28927 2.22685 5.46381 1.77751C5.65628 1.26449 5.99943 0.82169 6.44817 0.507269C6.89692 0.192849 7.43027 0.0215154 7.97817 0.0157759C8.74149 0.0055984 9.50888 -0.0264601 10.2651 0.0468183C11.4268 0.15928 12.1922 0.837104 12.5825 1.92712C12.955 2.96675 13.3117 4.01351 13.6674 5.05824C13.7142 5.19462 13.7738 5.24042 13.9219 5.23991C15.2068 5.23278 16.4917 5.23584 17.7766 5.23584L18 5.23635ZM5.24143 5.22719H12.7484C12.7306 5.16358 12.7184 5.10964 12.7006 5.05773C12.3744 4.1158 12.0507 3.17489 11.7199 2.23397C11.4212 1.38567 10.7683 0.92972 9.86458 0.927684C9.2926 0.927684 8.72029 0.927684 8.14763 0.927684C7.21028 0.927684 6.56451 1.38567 6.25664 2.26603C5.93435 3.18913 5.61393 4.11258 5.29538 5.03636C5.27807 5.09386 5.26179 5.15442 5.24194 5.22719H5.24143ZM9.42237 12.925V7.82093H8.53794V12.925H9.42237ZM12.671 12.5433V8.19699H11.7815V12.5438L12.671 12.5433ZM5.28367 8.19496V12.5438H6.17369V8.19496H5.28367Z"
                  fill="#047422"
                />
              </svg>{' '}
              Add to cart
            </p>
          </button>
          :
          <Link to={`/product/${product.slug}`} className="cart-btn" >
            <p>Buy Now</p> 
          </Link>
        }
      </div>
      </div>
    </div>
  );
};

export default ProductCard;
