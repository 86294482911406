import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const ChefsRecipesSection = ({ recipesData }) => {

  const apiUrl = process.env.REACT_APP_WORDPRESS_API_URL;

  const sectionStyle = `.carousel .carousel-control-dots {
      display: none !important; /* Ensure dots are hidden */
    }.carousel .slider-wrapper.axis-horizontal .slide{
    padding: 20px
  }`;


  return (
    <section className="chefs-recipes chefsRecipe custom-border-btm">
      <div className="container">
        <div className="row align-items-center recipe-topContent">
          <div className="col-lg-5 col-md-11">
            <div className="recipes-content">
              <div className="title textsec our-textsec">
                <h2 dangerouslySetInnerHTML={{ __html: recipesData.recipes_section_title }} />
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-11">
            <div className="recipes-content">
              <div className="sub-title">
                <p className="text-p" dangerouslySetInnerHTML={{ __html: recipesData.recipes_section_description }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="chefs-recipes-inner hasWidget">
        <style>{sectionStyle}</style>
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
          dynamicHeight={true}
          emulateTouch={true}
          centerMode={true}
          centerSlidePercentage={30}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                centerSlidePercentage: 50,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 600,
              settings: {
                centerSlidePercentage: 100,
                slidesToShow: 1,
              },
            },
          ]}
        >
          {recipesData.recipe_items.map((item, index) => (
            <div className="item" key={index}>
              <div className="chefs-recipes-card">
                <div className="recipes-card-img">
                  <img
                    loading="lazy"
                    src={item.recipe_image.replace(process.env.REACT_APP_WORDPRESS_API_URL+'/wp-content/','')}
                    className="inner-img img-fluid"
                    alt="Chefs Recipes"
                  />
                </div>
                <div className="card-text">
                  <h3 dangerouslySetInnerHTML={{ __html: item.recipe_title }} />
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="custom-btn">
              <a href={recipesData.recipes_read_link} className="border-animate">
                <span dangerouslySetInnerHTML={{ __html: recipesData.recipe_read_more }} />
                <img
                  loading="lazy"
                  src={`/assets/img/right-arrow.svg`}
                  className="right-arrow"
                  alt="right-arrow"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChefsRecipesSection;
