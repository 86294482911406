import React from 'react';

const AboutSection = ({ data }) => {
  return (
    <section className="about-us position-relative section-gap pb-0">
      <div className="container">
        <div className="row justify-content-lg-between justify-content-md-around">
          <div className="col-lg-2 col-md-4 col-5">
            <div className="about-sub-img">
              <img loading="lazy" alt="image" src={data.about_top_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content', '')} className="img-fluid" />
            </div>
            <div className="about-sub-text">
              <p>{data.about_title}</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="about-content about-page-content">
              <div className="desc">
                <p dangerouslySetInnerHTML={{ __html: data.about_text }} />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="about-page-banner-sign">
              <p>{data.about_watermark_text}</p>
            </div>
            <div className="about-banner about-page-banner">
              <img loading="lazy" alt="image" src={data.about_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content', '')} className="img-fluid lazyimage" />
            </div>
          </div>
        </div>
      </div>
      <div className="watermark-img">
        <img loading="lazy" alt="image" src={data.about_watermark_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content', '')} className="img-fluid" />
      </div>
    </section>
  );
};

export default AboutSection;
