import React from 'react';

const OrderItem = ({ item }) => {
  return (
    <tr>
      <td>
        <h5>
          {item.name} <span>× {item.qty}</span><br />
          {item.material && (
            <>
              <span>Medal Material:</span> {item.material}<br />
            </>
          )}
          {item.color && (
            <>
              <span>Color:</span> {item.color}
            </>
          )}
        </h5>
      </td>
      <td>${(item.price * item.qty).toFixed(2)}</td>
    </tr>
  );
};

export default OrderItem;
