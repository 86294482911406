import { FETCH_HEADER_SUCCESS, FETCH_HEADER_ERROR } from '../actions/headerActions';

const initialState = {
  data: null,
  error: null,
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HEADER_SUCCESS:
      return { ...state, data: action.payload, error: null };
    case FETCH_HEADER_ERROR:
      return { ...state, data: null, error: action.payload };
    default:
      return state;
  }
};

export default headerReducer;
