import React, {useEffect} from 'react';
import SocialLink from '../common/SocialLink';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';




const Header = ({ headerOptions }) => {

  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    // Initialize jQuery for hamburger menu
    $(document).ready(function () {
      $(".hamburger").click(function () {
        $(this).toggleClass("is-active");
      });
    });

    // Initialize vanilla JavaScript for closing menu when clicking outside
    const menuButton = document.querySelector('.navbar-toggler');
    const menuContent = document.querySelector('#navbarSupportedContent');

    const handleClickOutside = (event) => {
      if (
        menuContent.classList.contains('show') &&
        !menuButton.contains(event.target) &&
        !menuContent.contains(event.target)
      ) {
        const bootstrapMenu = new bootstrap.Collapse(menuContent);
        bootstrapMenu.hide();
        menuButton.querySelector('.hamburger').classList.remove('is-active');
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const cartItemCount = (cart.items) ? cart.items.reduce((acc, item) => acc + item.qty, 0) :'';

  const renderSocialLinks = () => {
    if (!headerOptions.email_address || !headerOptions.email_address.data.connect_social_icons) return null;
    return (
      <ul>
        {headerOptions.email_address.data.connect_social_icons.map((icon, index) => (
          <React.Fragment key={index}>
            {icon.fb_link && <SocialLink href={icon.fb_link} iconClass="fab fa-facebook-f" ariaLabel="Facebook" />}
            {icon.ld_link && <SocialLink href={icon.ld_link} iconClass="fab fa-linkedin-in" ariaLabel="LinkedIn" />}
            {icon.tw_link && <SocialLink href={icon.tw_link} iconClass="fab fa-twitter" ariaLabel="Twitter" />}
            {icon.instagram_link && <SocialLink href={icon.instagram_link} iconClass="fab fa-instagram" ariaLabel="Instagram" />}
            {icon.envelope_link && <SocialLink href={icon.envelope_link} iconClass="fas fa-envelope" ariaLabel="Envelope" />}
          </React.Fragment>
        ))}
      </ul>
    );
  };

  const renderMenuItems = (menu) => {
    if (!menu) return null;
    return menu.map((item) => {
      const hasChildren = item.children && item.children.length > 0;
      const itemClasses = [
        'menu-item',
        'menu-item-type-post_type',
        'menu-item-object-page',
        hasChildren ? 'menu-item-has-children' : '',
        `menu-item-${item.ID}`,
        'nav-item'
      ].join(' ');

      return (
        <li className={itemClasses} key={item.ID}>
          <Link className="nav-link menu-link" to={item.url || '#'}>{item.title}</Link>
          {hasChildren && (
            <ul className="sub-menu">
              {renderMenuItems(item.children)}
            </ul>
          )}
        </li>
      );
    });
  };

 

  return (
    <div className="sticky-top">
      <header className="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="top-left justify-content-start">
                {headerOptions.email_address && headerOptions.email_address.data.connect_social_icons && (
                  <div className="social-media">
                    <ul className="list-unstyled social-item">
                      {renderSocialLinks()}
                    </ul>
                  </div>
                )}
                <div className="top-mail">
                  <div className="mail-icon"><i className="far fa-envelope"></i></div>
                  <a href={headerOptions.email_link || '#'}>
                    <div className="mail-text">acf.usa.secretary@outlook.com</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="top-right justify-content-end">
              <div className="donate-btn">
                  <a href="/cart">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                      <path
                        d="M18 5.23635V6.1452H17.1044L16.708 8.14254C16.3395 9.99893 15.9848 11.8573 15.5976 13.7107C15.4937 14.2185 15.2169 14.6745 14.8143 15.001C14.4117 15.3274 13.9084 15.5041 13.3901 15.5009C10.464 15.5121 7.53799 15.5121 4.61195 15.5009C3.47003 15.4963 2.57644 14.7025 2.34948 13.5565C1.8691 11.1139 1.38635 8.67127 0.901219 6.22866C0.897657 6.21034 0.89155 6.19202 0.881882 6.15792H0V5.24194H4.25319C4.49949 4.53359 4.74121 3.83795 4.98394 3.14283C5.14271 2.68738 5.28927 2.22685 5.46381 1.77751C5.65628 1.26449 5.99943 0.82169 6.44817 0.507269C6.89692 0.192849 7.43027 0.0215154 7.97817 0.0157759C8.74149 0.0055984 9.50888 -0.0264601 10.2651 0.0468183C11.4268 0.15928 12.1922 0.837104 12.5825 1.92712C12.955 2.96675 13.3117 4.01351 13.6674 5.05824C13.7142 5.19462 13.7738 5.24042 13.9219 5.23991C15.2068 5.23278 16.4917 5.23584 17.7766 5.23584L18 5.23635ZM5.24143 5.22719H12.7484C12.7306 5.16358 12.7184 5.10964 12.7006 5.05773C12.3744 4.1158 12.0507 3.17489 11.7199 2.23397C11.4212 1.38567 10.7683 0.92972 9.86458 0.927684C9.2926 0.927684 8.72029 0.927684 8.14763 0.927684C7.21028 0.927684 6.56451 1.38567 6.25664 2.26603C5.93435 3.18913 5.61393 4.11258 5.29538 5.03636C5.27807 5.09386 5.26179 5.15442 5.24194 5.22719H5.24143ZM9.42237 12.925V7.82093H8.53794V12.925H9.42237ZM12.671 12.5433V8.19699H11.7815V12.5438L12.671 12.5433ZM5.28367 8.19496V12.5438H6.17369V8.19496H5.28367Z"
                        fill="#047422"
                      />
                    </svg>({cartItemCount})&nbsp; 
                   </a>
                </div>
                <div className="donate-btn">
                  <a href="/make-donation">
                    <button className="">&nbsp;  Donate Here</button>
                  </a>
                </div>
                <div className="login-btn">
                  <button className="" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <div className="user-icon">
                      <img src={process.env.PUBLIC_URL+"/assets/images/user-svg-icon.svg"} alt={headerOptions.my_member_area_icon?.alt || 'User Icon'} className="img-fluid" />
                    </div>
                    <div className="user-name">
                     <a href='/my-account/'><p>My Account</p></a> 
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="navigation-bar">
        <div className="container-fluid">
          <nav className="navbar py-0 navbar-expand-lg custom-navbar">
            <div className="container-fluid">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <div className="hamburger" id="hamburger-6">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </div>
              </button>
              <div className="navbar-logo">
                <a href="/">
                  <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} loading="lazy" className="img-fluid mx-300" alt={headerOptions.header_logo?.alt || 'Logo'} />
                </a>
              </div>
              <div className="collapse navbar-collapse siteNav" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 left-menu-items">
                  {renderMenuItems(headerOptions.primary_menu)}
                </ul>
                <div className="right-nav siteNav">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 left-menu-items">
                    {renderMenuItems(headerOptions.secondary_menu)}
                  </ul>
                </div>
              </div>
              <div className="mobile-icon">
                <div className="login-btn">
                  <button className="" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                    <div className="user-icon">
                      <img loading="lazy" alt="User Icon" src={process.env.PUBLIC_URL+"/assets/images/user-svg-icon.svg"} className="img-fluid w-19" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
