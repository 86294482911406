import React, { useEffect, useState } from 'react';
import axios from 'axios';


const PartnerSection = ({partnerData}) => {
  
  const apiUrl = process.env.REACT_APP_WORDPRESS_API_URL;

  return (
    <section className="our-partner ourPartner custom-border-btm">
      <div className="parent">
        <div
          id="rightBannerimg"
          className="img hand"
          style={{ backgroundImage: `url(${partnerData.partner_image.replace(process.env.REACT_APP_WORDPRESS_API_URL+'/wp-content/', '')})` }}
        ></div>
      </div>
      <div className="acf-text">
        <span dangerouslySetInnerHTML={{ __html: partnerData.partner_watermark_text }} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-lg-start text-center">
            <div className="partner-content textsec">
              <div className="title ourpatner-textsec">
                <h2 dangerouslySetInnerHTML={{ __html: partnerData.partner_name }} />
              </div>
              <div className="desc">
                <p dangerouslySetInnerHTML={{ __html: partnerData.partner_content }} />
              </div>
            </div>
            <div className="custom-btn-2">
              <a href={partnerData.partner_link}>
                <button className="btnDefault" aria-label="Follow Us on LinkedIn">
                  <span>Read More</span>
                  <span className="ps-2">
                    <img
                      loading="lazy"
                      src={`/assets/img/white-right-arrow.svg`}
                      className="right-arrow change-img1"
                      alt="right-arrow"
                    />
                    <img
                      loading="lazy"
                      src={`/assets/img/right-arrow.svg`}
                      className="right-arrow change-img2"
                      alt="right-arrow"
                    />
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerSection;
