import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tab } from '@headlessui/react';

import RelatedProductCard from '../components/product/RelatedProductCard';
import QuantityBox from '../components/common/QuantityBox';
import './ProductDetail.css';
import { addToCart } from '../redux/actions/cartActions'; 
import DonateSection from '../components/common/DonateSection';
import ImageGallery from 'react-image-gallery';
import Preloader from '../components/common/Preloader';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const ProductDetail = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState({ size: null, color: null });
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize the dispatch

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await fetch(`/json/${slug}.json`);
        if (!response.ok) throw new Error('Product not found');
        const data = await response.json();
        if (data.product.slug === slug) {
          setProduct(data.product);
          setRelatedProducts(data.related_product);
        } else {
          throw new Error('Product not found');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [slug]);

  const handleAddToCart = (product, quantity = 1) => {
    if (product) {
      const item = product.variation ? { ...product.variation, qty: Number(quantity) } : { ...product, qty: Number(quantity) };    
      dispatch(addToCart(item, Number(quantity)));
    }
  };

  const handleVariationSelect = (attribute, value) => {
    setSelectedAttributes((prev) => ({ ...prev, [attribute]: value }));
  
    const variation = product.variation_product.find((variation) => {
      return Object.entries(variation.attributes).every(([attrKey, attrValue]) => {
        const attributeKey = attrKey.replace('attribute_pa_', '');
        const selectedValue = attribute === attributeKey ? value : selectedAttributes[attributeKey];
        return attrValue === selectedValue || selectedValue === undefined;
      });
    });
  
    setSelectedVariation(variation);
  };
  

  if (loading) {
    return <Preloader />;
  }
  
  if (error) return <div>Error: {error}</div>;

  const galleryImages = product.product_gallery.map(image => ({
    original: `${process.env.REACT_APP_FRONTEND_URL}/uploads/${image}`,
    thumbnail: `${process.env.REACT_APP_FRONTEND_URL}/uploads/${image}`
  }));

  const getShortDescription = (text) => {
    const words = text.split(' ');
    return words.slice(0, 5).join(' ') + (words.length > 5 ? '...' : '');
  };

  return (
    <>
      <section className="shop-detail-sec">
        <div className="container-fluid">
          <div className="shop-detail-container">
            <div className="row">
              <div className="col-lg-8">
                <div className="shop-detail-left-sec">
                  <a href="#" className="shop-back-btn" onClick={() => navigate(-1)}>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                        <path d="M7.57031 0C7.57031 0 7.6041 6.43512 0.9994 7.70167" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10"/>
                        <path d="M20 7.69922H0" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10"/>
                        <path d="M1.00072 7.7002C1.00072 7.7002 7.22401 8.84734 7.1178 15.2739" stroke="#068F2B" strokeOpacity="0.96" strokeWidth="1.4" strokeMiterlimit="10"/>
                      </svg>
                    </span>
                    Back
                  </a>
                  <div className="image-gallery">
                    <ImageGallery
                      items={galleryImages}
                      showThumbnails={true}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showNav={false}
                      useBrowserFullscreen={false}
                      thumbnailPosition="left"
                      slideDuration={450}
                      slideInterval={2000}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 d-flex align-items-center">
                <div className="shop-detail-right-sec">
                  <h1>
                    {product.sale_price ? (
                      <>
                        <span className="sale-price">${product.sale_price}</span>
                        <span className="regular-price">${product.regular_price}</span>
                      </>
                    ) : (
                      <span className="regular-price">${product.price}</span>
                    )}
                  </h1>
                  <h2>{product.name}</h2>
                  <p>
                    {showFullDescription 
                      ? product.description 
                      : getShortDescription(product.short_description)}
                    <span 
                      className="more-text" 
                      style={{ display: showFullDescription ? 'inline' : 'none' }}
                    >
                      {product.description}
                    </span>
                    <span 
                      className="read-more" 
                      onClick={() => setShowFullDescription(!showFullDescription)}
                    >
                      {showFullDescription ? 'Read less' : 'Read more'}
                    </span>
                  </p>
                  <div className="stars">
                    {[...Array(5)].map((_, i) => (
                      <svg key={i} xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z" fill={i < 4 ? "#FFBC25" : "#CFCCCC"}/>
                      </svg>
                    ))}
                  </div>
                  {product.product_attributes && product.product_attributes.size && (
                    <>
                      <h4>SELECT SIZE</h4>
                      <div className="size-box">
                        {product.product_attributes.size.map(size => (
                          <a
                            key={size}
                            title={size.toUpperCase()}
                            href="javascript:void(0)"
                            className={selectedAttributes.size === size ? 'selected' : ''}
                            onClick={() => handleVariationSelect('size', size)}
                          >
                            {size.charAt(0).toUpperCase()}
                          </a>
                        ))}
                      </div>
                    </>
                  )}
                  {product.product_attributes && product.product_attributes.color && (
                    <>
                      <h4>SELECT COLOR</h4>
                      <div className="size-box">
                        {product.product_attributes.color.map(color => (
                          <a
                            key={color}
                            title={color.toUpperCase()}
                            style ={{backgroundColor: color, color: '#fff'}}
                            href="javascript:void(0)"
                            className={selectedAttributes.color === color ? 'selected' : ''}
                            onClick={() => handleVariationSelect('color', color)}
                          >
                           
                          </a>
                        ))}
                      </div>
                    </>
                  )}
                  <div className="variations">
                    {selectedVariation && (
                      <div className="variation">                       
                        <p>
                          Price: <span className="price" dangerouslySetInnerHTML={{ __html: selectedVariation.price_html}}/>
                        </p>
                      </div>
                    )}
                  </div>
                              
                  <QuantityBox onQuantityChange={handleQuantityChange} />
                  
                  {product.product_type === 'variable' ? (
                    <button 
                      className='shop-btn shop-detail-btn' 
                      onClick={() => handleAddToCart({...product, qty: quantity}, quantity)} 
                      disabled={!selectedVariation}
                    >
                      Add to Cart
                    </button>
                  ) : (
                    <button 
                      className='shop-btn shop-detail-btn' 
                      onClick={() => handleAddToCart({...product, qty: quantity}, quantity)}
                    >
                      Add to Cart
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="shop-detail-tabs">
        <div className="container-fluid">
          <div className="shop-tabs-container">
            <div className="row">
              <div className="col-lg-12">
                <div className="shop-tab-info">
                  <Tab.Group>
                    <Tab.List className="tab-buttons nav nav-tabs">
                      <Tab as="button" className="nav-link">Description</Tab>
                      <Tab as="button" className="nav-link">Additional Information</Tab>
                      <Tab as="button" className="nav-link">Reviews</Tab>
                    </Tab.List>

                    <Tab.Panels>
                      <Tab.Panel className="tab-content">
                        <p dangerouslySetInnerHTML={{ __html: product.description}} />
                      </Tab.Panel>
                      <Tab.Panel className="tab-content">
                        <p>Additional information about the product.</p>
                      </Tab.Panel>
                      <Tab.Panel className="tab-content">
                        <p>Reviews of the product.</p>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </section>    
          
      <section className="shop-detail-slide">
        <div className="container-fluid">
          <div className="shop-slider-container">
            <div className="row">
              <div className="col-lg-12">
                <h3>Related Products</h3>
                <div className="row g-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Carousel
                      showArrows={true}
                      autoPlay={true}
                      infiniteLoop={true}
                      showStatus={false}
                      showThumbs={false}
                      emulateTouch={true}
                      interval={3000}
                      centerMode={true}
                      centerSlidePercentage={33.33}
                      responsive={[
                        {
                          breakpoint: 1024,
                          settings: {
                            centerSlidePercentage: 50,
                            slidesToShow: 2,
                          },
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            centerSlidePercentage: 100,
                            slidesToShow: 1,
                          },
                        },
                      ]}
                    >
                      {relatedProducts.map((relatedProduct) => (
                        <RelatedProductCard 
                          key={relatedProduct.id}                         
                          relatedProduct={relatedProduct}   
                          handleAddToCart={handleAddToCart}
                        />
                      ))}
                    </Carousel>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  
      <DonateSection />
    </>
  );
};

export default ProductDetail;
