import React from 'react';


const Preloader = () => {
  return (
    <div className="preloader">
      <div className="loader-img">
        <img src="/loader.png" className="img-responsive" alt="Loader Img" />
      </div>
    </div>
  );
};

export default Preloader;