// src/components/AboutCompanySection.js
import React from 'react';

const AboutCompanySection = ({ data }) => {
  return (
    <section className="about-company section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="about-company-info">
              <p dangerouslySetInnerHTML={{ __html: data.about_description }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCompanySection;
