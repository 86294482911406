// actions/footerActions.js
export const FETCH_FOOTER_SUCCESS = 'FETCH_FOOTER_SUCCESS';
export const FETCH_FOOTER_ERROR = 'FETCH_FOOTER_ERROR';

export const fetchFooterSuccess = (data) => ({
  type: FETCH_FOOTER_SUCCESS,
  payload: data,
});

export const fetchFooterError = (error) => ({
  type: FETCH_FOOTER_ERROR,
  payload: error,
});
