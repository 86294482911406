import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchHeaderData } from '../../thunks/headerThunk';
import { fetchFooterData } from '../../thunks/footerThunk';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Preloader from '../common/Preloader';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const headerData = useSelector((state) => state.header.data);
  const footerData = useSelector((state) => state.footer.data);
  const headerError = useSelector((state) => state.header.error);
  const footerError = useSelector((state) => state.footer.error);

  useEffect(() => {
    dispatch(fetchHeaderData());
    dispatch(fetchFooterData());
  }, [dispatch]);

  if (!headerData || !footerData) {
    return <Preloader />;
  }

  if (headerError || footerError) {
    return <div>{headerError || footerError}</div>;
  }

  return (
    <>
      <Header headerOptions={headerData} />
      <main>{children}</main>
      <Footer footerOptions={footerData} />
    </>
  );
};

export default Layout;
