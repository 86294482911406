import React, { useState } from 'react';
import axios from 'axios';
import LoadingSpinner from '../common/LoadingSpinner'; // Ensure the correct path to the file

const ContactForm = ({ data }) => {

    const [formData, setFormData] = useState({ first: '', last: '', email: '', comment: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true); // Show spinner

        axios.post('/wp-json/custom/v1/submit-wpform', formData)
            .then(response => {
                console.log('Form submitted successfully:', response.data);
                setFormData({ first: '', last: '', email: '', comment: '' }); // Reset form
                setIsLoading(false); // Hide spinner
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                setIsLoading(false); // Hide spinner
            });
    };

    const leftFormStyle = {
        backgroundImage: `url(${data.left_banner})`,
    };

    return (
        <section className="contact-form-sec">
            {isLoading && <LoadingSpinner />}
            <div className="container-fluid">
                <div className="row justify-content-end">
                    <div className="col-lg-10">
                        <div className="contact-form-image" style={leftFormStyle}></div>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                >
                                    <div className="white-bg-contact">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="11"
                                            viewBox="0 0 15 11"
                                            fill="none"
                                        >
                                            <path
                                                d="M13.1875 0.5H1.8125C1.08789 0.5 0.5 1.06612 0.5 1.76389V9.34722C0.5 10.045 1.08789 10.6111 1.8125 10.6111H13.1875C13.9121 10.6111 14.5 10.045 14.5 9.34722V1.76389C14.5 1.06612 13.9121 0.5 13.1875 0.5ZM13.1875 1.76389V2.83819C12.575 3.32005 11.5961 4.06785 9.50703 5.64245C9.04766 5.99002 8.13438 6.82998 7.5 6.81944C6.86562 6.82998 5.95234 5.99002 5.49297 5.64245C3.40391 4.06785 2.425 3.32005 1.8125 2.83819V1.76389H13.1875ZM1.8125 9.34722V4.46019C2.43867 4.94204 3.32734 5.61612 4.68086 6.63513C5.27969 7.08802 6.32422 8.0886 7.5 8.08333C8.66758 8.0886 9.70117 7.10382 10.3191 6.63776C11.6727 5.61612 12.5613 4.94204 13.1875 4.46019V9.34722H1.8125Z"
                                                fill="#047422"
                                            />
                                        </svg>
                                    </div>
                                    <p>Contact Us</p>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false"
                                >
                                    <div className="green-bg-contact">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="15"
                                            viewBox="0 0 10 15"
                                            fill="none"
                                        >
                                            <path
                                                d="M1.0707 9.26416C1.0707 7.93868 2.14522 6.86416 3.4707 6.86416H6.52972C7.85521 6.86416 8.92972 7.93868 8.92972 9.26416V13.8996H1.0707V9.26416Z"
                                                stroke="white"
                                                strokeWidth="1.2"
                                            />
                                            <circle cx="5.00027" cy="2.55887" r="2.05887" fill="white" />
                                        </svg>
                                    </div>
                                    <p>Become Member</p>
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                            >
                                <div className="form-container-main">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <form className="form-container" onSubmit={handleSubmit}>
                                                <div className="wpforms-container wpforms-container-full" id="wpforms-19835">
                                                    <div className="wpforms-field-container">
                                                        <div
                                                            id="wpforms-19835-field_0-container"
                                                            className="wpforms-field wpforms-field-name"
                                                            data-field-id="0"
                                                        >
                                                            <input
                                                                type="text"
                                                                id="wpforms-19835-field_0"
                                                                className="wpforms-field-name-first wpforms-field-required"
                                                                name="first"
                                                                placeholder="First name"
                                                                value={formData.first}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                            <input
                                                                type="text"
                                                                id="wpforms-19835-field_0-last"
                                                                className="wpforms-field-name-last wpforms-field-required"
                                                                name="last"
                                                                placeholder="Last name"
                                                                value={formData.last}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div
                                                            id="wpforms-19835-field_1-container"
                                                            className="wpforms-field wpforms-field-email"
                                                            data-field-id="1"
                                                        >
                                                            <input
                                                                type="email"
                                                                id="wpforms-19835-field_1"
                                                                className="wpforms-field-large wpforms-field-required"
                                                                name="email"
                                                                placeholder="Email"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div
                                                            id="wpforms-19835-field_2-container"
                                                            className="wpforms-field wpforms-field-textarea"
                                                            data-field-id="2"
                                                        >
                                                            <textarea
                                                                id="wpforms-19835-field_2"
                                                                className="wpforms-field-medium"
                                                                name="comment"
                                                                placeholder="Leave a comment here"
                                                                value={formData.comment}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="wpforms-submit-container">
                                                        <button type="submit" name="wpforms[submit]" className="wpforms-submit">
                                                            SUBMIT
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-lg-5"></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                            >
                                <div className="form-container-main">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <form className="form-container" onSubmit={handleSubmit}>
                                                <div className="wpforms-container wpforms-container-full" id="wpforms-19835">
                                                    <div className="wpforms-field-container">
                                                        <div
                                                            id="wpforms-19835-field_0-container"
                                                            className="wpforms-field wpforms-field-name"
                                                            data-field-id="0"
                                                        >
                                                            <input
                                                                type="text"
                                                                id="wpforms-19835-field_0"
                                                                className="wpforms-field-name-first wpforms-field-required"
                                                                name="first"
                                                                placeholder="First name"
                                                                value={formData.first}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                            <input
                                                                type="text"
                                                                id="wpforms-19835-field_0-last"
                                                                className="wpforms-field-name-last wpforms-field-required"
                                                                name="last"
                                                                placeholder="Last name"
                                                                value={formData.last}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div
                                                            id="wpforms-19835-field_1-container"
                                                            className="wpforms-field wpforms-field-email"
                                                            data-field-id="1"
                                                        >
                                                            <input
                                                                type="email"
                                                                id="wpforms-19835-field_1"
                                                                className="wpforms-field-large wpforms-field-required"
                                                                name="email"
                                                                placeholder="Email"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div
                                                            id="wpforms-19835-field_2-container"
                                                            className="wpforms-field wpforms-field-textarea"
                                                            data-field-id="2"
                                                        >
                                                            <textarea
                                                                id="wpforms-19835-field_2"
                                                                className="wpforms-field-medium"
                                                                name="comment"
                                                                placeholder="Leave a comment here"
                                                                value={formData.comment}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="wpforms-submit-container">
                                                        <button type="submit" name="wpforms[submit]" className="wpforms-submit">
                                                            SUBMIT
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-lg-5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;