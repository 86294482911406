// src/components/Signup.js
import React, { useState } from 'react';
import axios from 'axios';
import Preloader from '../common/Preloader';
import '../Auth.css'; 
import Spinner from '../common/Spinner';

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const resetForm = () => {
    setFormData({
      username: '',
      email: '',
      password: '',
    });
    setError(null);
   // setSuccessMessage('');
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const response = await axios.post(process.env.REACT_APP_WORDPRESS_API_URL+'/wp-json/wp/v2/users/register', {
        username: formData.username,
        email: formData.email,
        password: formData.password,
      });
      setLoading(false);
      setSuccessMessage('User registered successfully. Please login.');
      resetForm();
     
    } catch (error) {
      setError('Error registering user. Please try again.');
      setSuccessMessage('');
    } finally{
        setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      {loading ?
        (<Spinner />)
      :
      (
      <>  
      <h2>Signup</h2>
      {error && <p className="auth-error">{error}</p>}
      {successMessage && <p className="auth-success">{successMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div className="auth-input">
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className="auth-input">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="auth-input">
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <button className="auth-button" type="submit">Signup</button>
      </form>
      </>
    )}
    </div>
    
  );
};

export default Signup;
