import React from 'react';

const InnerBanner = ({ data }) => {
  if (!data) return null;

  const bannerStyle = {
    backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0.75%, rgba(0, 0, 0, 0.00) 111.99%), url(${data.banner_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content', '')})`,
  };

  return (
    <section className={`page-banner page-border ${data.section_class}`} style={bannerStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 px-0">
            <div className={`page-banner-title ${data.big_banner_text_class}`}>
              <h2>{data.title}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InnerBanner;
