import React, { useState } from 'react';
import CircleLoader from 'react-spinners/CircleLoader';

const Spinner = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="sweet-loading">
      <CircleLoader color={"#FFDCC0"} justify-content={"center"} loading={loading} size={150} />
    </div>
  );
};

export default Spinner;
