import React from 'react';
import './DonateSection.css'; // Make sure to create and import a corresponding CSS file for styling

const DonateSection = () => {
    return (
        <section className="donate-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 donate-btm-gap">
                        <div className="donate-main text-center">
                            <div className="donate-content text-center">
                                <h2>Donate Today!</h2>
                                <h5>"Elevate Culinary Excellence: Support Our Passion!"</h5>
                                <p>"Savoring Success: Donate To Empower Young Chefs And Fuel Team USA's Culinary Triumphs!"</p>
                                <div className="custom-btn-2">
                                    <a href="https://acf-usa.org/demo/make-donation/">
                                        <button className="btnDefault">
                                            <span>Donate here! </span>
                                            <span className="ps-2">
                                                <img 
                                                    loading="lazy" 
                                                    alt="Donate" 
                                                    src="https://acf-usa.org/demo/wp-content/themes/academie-culinaire/assets/img/white-right-arrow.svg" 
                                                    className="right-arrow change-img1"
                                                />
                                                <img 
                                                    loading="lazy" 
                                                    alt="Donate" 
                                                    src="https://acf-usa.org/demo/wp-content/themes/academie-culinaire/assets/img/right-arrow.svg" 
                                                    className="right-arrow change-img2"
                                                />
                                            </span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DonateSection;
