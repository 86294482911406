import { SET_FILTERS } from '../actions/filterActions';

const initialState = {
  categories: [],
  minPrice: 0,
  maxPrice: 444.99,
  colors: [],
  sizes: [],
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
