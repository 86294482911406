import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { productListReducer } from './reducers/productReducer';
import { cartReducer } from './reducers/cartReducer';
import { filterReducer } from './reducers/filterReducer';
import headerReducer from './reducers/headerReducer';
import footerReducer from './reducers/footerReducer';

// Combine reducers into a root reducer
const rootReducer = combineReducers({
  header: headerReducer,
  footer: footerReducer,
  products: productListReducer,
  cart: cartReducer,
  filters: filterReducer,
});

// Retrieve cart items from local storage or set default value
const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : [];

// Define initial state for the store
const initialState = {
  cart: { items: cartItemsFromStorage },
};

// Create Redux store with reducers, initial state, and middleware
const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;