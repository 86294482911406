// actions/headerActions.js
export const FETCH_HEADER_SUCCESS = 'FETCH_HEADER_SUCCESS';
export const FETCH_HEADER_ERROR = 'FETCH_HEADER_ERROR';

export const fetchHeaderSuccess = (data) => ({
  type: FETCH_HEADER_SUCCESS,
  payload: data,
});

export const fetchHeaderError = (error) => ({
  type: FETCH_HEADER_ERROR,
  payload: error,
});
