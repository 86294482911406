import React, { useState, useEffect } from 'react';
import AccountTabs from '../components/my-account/AccountTabs';
import TabContent from '../components/my-account/TabContent';
import DonateSection from '../components/common/DonateSection';
import Login from '../components/my-account/Login';
import Signup from '../components/my-account/Signup';
import Preloader from '../components/common/Preloader';
import MyAccountBanner from '../components/my-account/MyAccountBanner';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/my-account/MyAccount.css';

const MyAccount = () => {
  
  const [activeTab, setActiveTab] = useState('dashboard');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLogin = (status) => {
    setIsLoggedIn(status);
  };

  const handleLogout = async () => {
    setLoading(true);
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setActiveTab('login');
    setLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <>
      <MyAccountBanner title="My Account" />
      <section className="account-detail-sec">
        <div className="container-fluid">
          <div className="account-detail-con">
            {loading && <Preloader />}
            {isLoggedIn ? (
              <div className="row">
                <div className="col-lg-3">
                  <AccountTabs activeTab={activeTab} onTabChange={handleTabChange} />
                </div>
                <div className="col-lg-9">
                  <div className="account-tab-right">
                    <div className="tab-content" id="myTabContent">
                      <TabContent activeTab={activeTab} onLogout={handleLogout} />
                    </div>
                  </div>  
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <Login onLogin={handleLogin} />
                </div>
                <div className="col-lg-6">
                  <Signup />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <DonateSection />
    </>
  );
};

export default MyAccount;
