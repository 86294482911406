import React from 'react';

const ContactQuote = ({data}) => {
  
  return (
    <section class="contact-sec">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="contact-p">
                        <p>{data.description}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default ContactQuote;
