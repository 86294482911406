import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Shop from './containers/Shop'; 
import Cart from './containers/Cart'; 
import Checkout from './containers/Checkout'; 
import ProductDetail from './pages/ProductDetail';
import NotFound from './pages/NotFound';
import store from './redux/store'
import MyAccount from './containers/MyAccount';
import Layout from './components/layout/Layout';
const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-acf-usa" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/shop" element={<Shop />} />            
            <Route path="/product/:slug" element={<ProductDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/my-account/" element={<MyAccount />}/>
            <Route path="*" element={<NotFound />} /> {/* 404 Route */}
          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;
