const BillingSection = ({ formData, handleChange }) => (
  <>
    <div className="col-md-6">
      <input
        type="text"
        className="form-control"
        id="billing_first_name"
        name="billing_first_name"
        placeholder="First Name"
        required
        value={formData.billing_first_name}
        onChange={handleChange}
      />
      <div className="invalid-feedback">First Name is required.</div>
    </div>
    <div className="col-md-6">
      <input
        type="text"
        className="form-control"
        id="billing_last_name"
        name="billing_last_name"
        placeholder="Last Name"
        required
        value={formData.billing_last_name}
        onChange={handleChange}
      />
      <div className="invalid-feedback">Last Name is required.</div>
    </div>
    <div className="col-md-6">
      <input
        type="email"
        className="form-control"
        id="billing_email"
        name="billing_email"
        placeholder="Email Address"
        required
        value={formData.billing_email}
        onChange={handleChange}
      />
      <div className="invalid-feedback">Email Address is required.</div>
    </div>
    <div className="col-md-6">
      <input
        type="number"
        className="form-control"
        id="billing_mobile_no"
        name="billing_mobile_no"
        placeholder="Number"
        required
        maxLength="10"
        value={formData.billing_mobile_no}
        onChange={handleChange}
      />
      <div className="invalid-feedback">Number is required.</div>
    </div>
    <h5 className="billing-heading">Billing address</h5>
    <p>Enter the billing address that matches your payment method.</p>
    <div className="col-12 mt-0">
      <input
        type="text"
        className="form-control"
        id="billing_street_address"
        name="billing_street_address"
        placeholder="Address"
        required
        value={formData.billing_street_address}
        onChange={handleChange}
      />
      <div className="invalid-feedback">Address is required.</div>
    </div>
    <div className="col-12">
      <input
        type="text"
        className="form-control"
        id="billing_apartment"
        name="billing_apartment"
        placeholder="Address 2"
        value={formData.billing_apartment}
        onChange={handleChange}
      />
    </div>
    <div className="col-md-6">
      <select
        id="billing_country_autocomplete"
        name="billing_country_autocomplete"
        className="form-select"
        required
        value={formData.billing_country_autocomplete}
        onChange={handleChange}
      >
        <option value="">Choose Country...</option>
        <option value="US">USA</option>
        <option value="IN">Inida</option>
      </select>
      <div className="invalid-feedback">Country is required.</div>
    </div>
    <div className="col-md-6">
      <select
        id="billing_state_autocomplete"
        name="billing_state_autocomplete"
        className="form-select"
        required
        value={formData.billing_state_autocomplete}
        onChange={handleChange}
      >
        <option value="">Choose State...</option>
        <option value="UP">Uttar Pradesh</option>
      </select>
      <div className="invalid-feedback">State is required.</div>
    </div>
    <div className="col-md-6">
      <input
        type="text"
        className="form-control"
        id="billing_city"
        name="billing_city"
        placeholder="City"
        required
        value={formData.billing_city}
        onChange={handleChange}
      />
      <div className="invalid-feedback">City is required.</div>
    </div>
    
    <div className="col-md-6">
      <input
        type="text"
        className="form-control"
        id="billing_zipcode"
        name="billing_zipcode"
        placeholder="Zip"
        required
        value={formData.billing_zipcode}
        onChange={handleChange}
      />
      <div className="invalid-feedback">Zip is required.</div>
    </div>
  </>
);

export default BillingSection;
