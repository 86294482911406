import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import AboutSection from '../components/about/AboutSection';
import AboutCompanySection from '../components/about/AboutCompanySection';
import AboutCompanyQuotes1 from '../components/about/AboutCompanyQuotes1';
import AboutCompanyQuotes2 from '../components/about/AboutCompanyQuotes2';
import DonateSection from '../components/common/DonateSection';
import InnerBanner from '../components/common/InnerBanner';
import Preloader from '../components/common/Preloader';


const About = () => {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAboutPageData = async () => {
      try {
        const response = await axios.get(`/json/about-acf-usa.json`);
        setAboutData(response.data);
      } catch (error) {
        console.error('Error fetching about page data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutPageData();
  }, []);

  const memoizedAboutData = useMemo(() => aboutData, [aboutData]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      <InnerBanner data={memoizedAboutData} />
      <AboutSection data={memoizedAboutData} />
      <AboutCompanySection data={memoizedAboutData} />
      <AboutCompanyQuotes1 data={memoizedAboutData} />
      <AboutCompanyQuotes2 data={memoizedAboutData} />
      <DonateSection data={memoizedAboutData} />
    </>
  );
};

export default About;