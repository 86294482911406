import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './ShopFilter.css';

const ShopFilter = ({ updateFilters }) => {
  const [filterData, setFilterData] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 500]); // Default maxPrice to 500 for initial state
  const filterUrl = '/json/shop_filter.json';

  useEffect(() => {
    fetch(filterUrl)
      .then(response => response.json())
      .then(data => {
        setFilterData(data);
        setPriceRange([parseFloat(data.minPrice), parseFloat(data.maxPrice)]);
      })
      .catch(error => {
        console.error('Error fetching filter data:', error);
      });
  }, []);

  useEffect(() => {
    if (filterData) {
      updateFilters(selectedCategories, priceRange[0], priceRange[1], selectedColors, selectedSizes);
    }
  }, [selectedCategories, priceRange, selectedColors, selectedSizes, filterData]);

  const handleCategoryChange = (event) => {
    const categoryId = parseInt(event.target.value);
    if (event.target.checked) {
      setSelectedCategories(prev => [...prev, categoryId]);
    } else {
      setSelectedCategories(prev => prev.filter(id => id !== categoryId));
    }
  };

  const handleColorChange = (event) => {
    const color = event.target.value;
    if (event.target.checked) {
      setSelectedColors(prev => [...prev, color]);
    } else {
      setSelectedColors(prev => prev.filter(c => c !== color));
    }
  };

  const handleSizeChange = (event) => {
    const size = event.target.value;
    if (event.target.checked) {
      setSelectedSizes(prev => [...prev, size]);
    } else {
      setSelectedSizes(prev => prev.filter(s => s !== size));
    }
  };

  const handlePriceChange = (range) => {
    setPriceRange(range);
  };

  if (!filterData) {
    return <div>Loading filters...</div>;
  }

  const { product_category, color, size } = filterData;

  return (
    <div className="sideBar_shop">
      <div className="sideBar_bx shop_sidebar_filter">
        <form id="shop-filters-form">
          <input type="hidden" name="listGrid" id="listGrid" value="grid" />
          <div className="widget_sidebar">
            <div className="paragraph_p2 sideHdng">Price</div>
            <div className="price_range_slider">
              <Slider
                range
                min={parseFloat(filterData.minPrice)}
                max={parseFloat(filterData.maxPrice)}
                value={priceRange}
                step={1}
                onChange={handlePriceChange}
              />
              <div className="price_range">
                <span className="paragraph_p3">Range: </span>
                <span id="price_range_from" className="paragraph_p3">${priceRange[0]}</span>
                <span className="paragraph_p3"> - </span>
                <span id="price_range_to" className="paragraph_p3">${priceRange[1]}</span>
              </div>
            </div>
          </div>
          <p className="paragraph_p2 first_heading_cat">PRODUCT CATEGORY</p>
          <div className="widget_sidebar">
            {product_category && product_category.map(category => (
              <div key={category.parent.term_id} className="accordion-item-dropdown withoutChildAccordion">
                <div className="cat_list_check b">
                  <input
                    type="checkbox"
                    id={`product_category_${category.parent.term_id}`}
                    value={category.parent.term_id}
                    name="product_category[]"
                    onChange={handleCategoryChange}
                    className="cx-checkboxField"
                  />
                  <label htmlFor={`product_category_${category.parent.term_id}`} className="paragraph_p3">
                    {category.parent.name}
                  </label>
                </div>
              </div>
            ))}
          </div>
          <p className="paragraph_p2 first_heading_cat">COLOR</p>
          <div className="widget_sidebar">
            {color && color.map(c => (
              <div key={c} className="cat_list_check b">
                <input
                  type="checkbox"
                  id={`color_${c}`}
                  value={c}
                  name="color[]"
                  onChange={handleColorChange}
                  className="cx-checkboxField"
                />
                <label htmlFor={`color_${c}`} className="paragraph_p3">
                  {c}
                </label>
              </div>
            ))}
          </div>
          <p className="paragraph_p2 first_heading_cat">SIZE</p>
          <div className="widget_sidebar">
            {size && size.map(s => (
              <div key={s} className="cat_list_check b">
                <input
                  type="checkbox"
                  id={`size_${s}`}
                  value={s}
                  name="size[]"
                  onChange={handleSizeChange}
                  className="cx-checkboxField"
                />
                <label htmlFor={`size_${s}`} className="paragraph_p3">
                  {s}
                </label>
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShopFilter;
