// src/components/AboutCompanyQuotes1.js
import React from 'react';

const AboutCompanyQuotes1 = ({ data }) => {
  return (
    <section className="about-company-quotes">
      <div className="container">
        <div className="row company-quotes">
          <div className="col-lg-7 company-quotes-img" style={{ backgroundImage: `url(${data.about_section_1_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content', '')})` }}></div>
          <div className="col-lg-5 d-flex">
            <div className="quotes-content align-self-center">
              <h2>{data.about_section_1_title}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCompanyQuotes1;
