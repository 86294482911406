import React from 'react';

const AccountTabs = ({ activeTab, onTabChange }) => {
  const tabs = [
    { id: 'dashboard', label: 'Dashboard' },
    { id: 'order', label: 'Orders' },
    { id: 'subscripion', label: 'Subscriptions' },
    { id: 'download', label: 'Downloads' },
    { id: 'address', label: 'Addresses' },
    { id: 'payment', label: 'Payment methods' },
    { id: 'account', label: 'Account details' },
    { id: 'logout', label: 'Log out' },
  ];

  return (
    <div className="account-tab-left">
      <ul className="nav nav-tabs" role="tablist">
        {tabs.map((tab) => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => onTabChange(tab.id)}
              type="button"
              role="tab"
              aria-controls={tab.id}
              aria-selected={activeTab === tab.id}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AccountTabs;
