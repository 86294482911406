import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Menu = ({ menuName }) => {
  const [menuItems, setMenuItems] = useState([]);
  const apiUrl = process.env.REACT_APP_WORDPRESS_API_URL;

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(`/json/${menuName}.json`);
        setMenuItems(response.data);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenuData();
  }, [apiUrl, menuName]);

  if (!menuItems.length) {
    return <div></div>;
  }

  return (
    <ul className="list-unstyled">
      {menuItems.map((item) => (
        <li key={item.ID}>
          <Link className="nav-link menu-link" to={item.url || '#'}>{item.title}</Link>        
        </li>
      ))}
    </ul>
  );
};

export default Menu;
