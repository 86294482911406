import React from 'react';
import { useNavigate } from 'react-router-dom';
const CartTotals = ({ subtotal }) => {
  const shipping = 24.99;
  const total = subtotal + shipping;

  const navigate = useNavigate();

  const handleCheckout = () => { 
    navigate('/checkout');
  };

  return (
    <div className="cart-sec-right">
      <h2>Cart Totals</h2>
      <table className="table">
        <tbody>
          <tr>
            <td>Subtotal</td>
            <td>${subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Shipping?</td>
            <td>${shipping.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td className="total-amount">${total.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <a href="javascript:void(0)" onClick={handleCheckout} className="shop-detail-btn"> CHECKOUT <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23" fill="none">
        <path d="M0 11.1503H30" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" />
        <path d="M20.9844 0C20.9844 0 20.9421 9.30833 29.198 11.1404" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" />
        <path d="M28.9176 11.1504C28.9176 11.1504 21.1384 12.8098 21.2712 22.1058" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" />
      </svg></span></a>
     
    </div>
  );
};

export default CartTotals;
