import React from 'react';

const AboutUsSection = ({aboutUsData}) => {

  const apiUrl = process.env.REACT_APP_WORDPRESS_API_URL;
  return (
    <section className="home-about-us position-relative custom-border-btm">
      <div className="container">
        <div className="row justify-content-between flex-wrap-reverse">
          <div className="col-lg-6 col-md-7 col-12">
            <div className="about-content textsec">
              <div className="title about-textsec">
                <h2 dangerouslySetInnerHTML={{ __html: aboutUsData.about_title }} />
              </div>
              <div className="desc">
                <p dangerouslySetInnerHTML={{ __html: aboutUsData.about_us_description }} />
              </div>
              <div className="custom-btn">
                <a
                  href={aboutUsData.about_read_more_link}
                  className="border-animate"
                >
                  <span dangerouslySetInnerHTML={{ __html: aboutUsData.about_read_more_text }} />
                  <img
                    loading="lazy"
                    alt="Academie Culinaire De France USA"
                    src={`${apiUrl}/wp-content/themes/academie-culinaire/assets/img/right-arrow.svg`}
                    className="right-arrow"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-12 mb-lg-0 mb-4">
            <div className="about-banner banner-image card-2">
              <img
                loading="lazy"
                src={aboutUsData.about_banner_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content', '')}
                className="img-fluid"
                alt="About Us"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="watermark-img">
      
        <img
          loading="lazy"
          src={aboutUsData.watermark_image.replace(process.env.REACT_APP_BACKEND_URL+'/wp-content', '')}
          className="img-fluid star"
          alt="watermark"
        />
      </div>
    </section>
  );
};

export default AboutUsSection;